import { TextField, Box } from '@mui/material';
import { MedicalNote } from '../../../services/types/matter-notes.types';
import NoteSection from '../components/NoteSection';
import BaseNoteForm, { useFormContext } from '../components/BaseNoteForm';

const MedicalFormFields = () => {
  const { handleChange, formData } = useFormContext();

  const inputSx = {
    fontSize: '0.875rem',
    color: 'info.light',
  };

  const labelSx = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: 'text.secondary',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TextField
        size="small"
        label="NAME"
        name="name"
        value={formData.name || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="ADDRESS"
        name="address"
        value={formData.address || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        multiline
        minRows={2}
        maxRows={4}
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="PHONE"
        name="phone"
        value={formData.phone || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="FAX"
        name="fax"
        value={formData.fax || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="EMAIL"
        name="email"
        type="email"
        value={formData.email || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
    </Box>
  );
};

const MedicalNoteForm: React.FC<{
  initialData?: MedicalNote;
  onSubmit: (data: Partial<MedicalNote>) => void;
  isSubmitting: boolean;
}> = ({ initialData, onSubmit, isSubmitting }) => {
  return (
    <BaseNoteForm
      initialData={initialData}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      expandedFields={['name', 'address', 'phone', 'fax', 'email']}
      prompt="A medical provider seen for treatment by the client"
    >
      <MedicalFormFields />
    </BaseNoteForm>
  );
};

const MedicalNoteSection: React.FC<{ matterId: string }> = ({ matterId }) => {
  return (
    <NoteSection
      matterId={matterId}
      noteType="medical"
      title="Medicals"
      FormFields={MedicalNoteForm}
    />
  );
};

export default MedicalNoteSection;
