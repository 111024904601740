import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useReadOnlyLetterPreview } from '../../../hooks/useReadOnlyLetterPreview';
import { DocumentInstance } from '../../../services/types/letters-types';
import { PDFPreview } from '../../base/PDFPreview';

interface PreviewLetterProps {
  open: boolean;
  onClose: () => void;
  document: DocumentInstance;
}

export const ReadOnlyPreviewLetter = ({
  open,
  onClose,
  document,
}: PreviewLetterProps) => {
  const { pdfBlob, isLoading } = useReadOnlyLetterPreview({
    open,
    documentId: document.id,
  });

  const handleDownload = () => {
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      const link = window.document.createElement('a');
      link.href = url;
      const filename = `${document.document_template.name || 'document'}.pdf`;
      link.setAttribute('download', filename);
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100vh - 128px)',
          maxWidth: '50rem',
          position: 'fixed',
          bottom: 0,
          marginBottom: 0,
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 6, py: 3 }}
        >
          <Typography variant="h2">
            {document.document_template.name}
          </Typography>
          <Stack direction="row" spacing={6}>
            <Stack direction="row" spacing={4}>
              <Button
                onClick={handleDownload}
                disabled={isLoading || !pdfBlob}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                  background: '#FFF',
                  color: '#000',
                  fontFamily: 'area-normal, sans-serif',
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  letterSpacing: '1px',
                  padding: '8px 12px',
                  textTransform: 'uppercase',
                  '&:disabled': {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  },
                }}
                size="small"
              >
                Download
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <PDFPreview blob={pdfBlob || null} width="100%" height="100%" />
      </Box>
    </Dialog>
  );
};
