import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

export const PDFPreview = ({
  blob,
  width,
  height,
}: {
  blob: Blob | null;
  width: number | string;
  height: number | string;
}) => {
  const [pdfObjectUrl, setPdfObjectUrl] = useState<string | null>(null);

  useEffect(() => {
    if (blob) {
      const blobUrl = URL.createObjectURL(blob);
      setPdfObjectUrl(blobUrl);

      return () => {
        URL.revokeObjectURL(blobUrl);
      };
    }

    return () => {};
  }, [blob]);

  if (!pdfObjectUrl) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={width}
        height={height}
      />
    );
  }

  return (
    <div style={{ width, height }}>
      <iframe
        title="PDF Preview"
        src={`${pdfObjectUrl}#toolbar=0&navpanes=0`}
        width="100%"
        height="100%"
      />
    </div>
  );
};
