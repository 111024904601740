import { Link } from 'react-router-dom';
import logo from './finch-logo-flat.svg';

export const FinchLogoFlat = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <Link to="/" style={{ display: 'flex', textDecoration: 'none' }}>
      <img
        src={logo}
        alt="Finch Logo"
        style={{ width: '24px', cursor: 'pointer', ...style }}
      />
    </Link>
  );
};
