import { Form } from './form-types';
import { MatterClient } from './matter-client-types';
import { User } from './user-types';

interface TaskSearchResults {
  count: number;
  next: string | null;
  previous: string | null;
  results: Task[];
}

// Types for Task and TaskUpdate
interface Task {
  id: string;
  matter: string;
  name: string;
  description?: string;
  due_date?: string;
  completed_at?: string;
  order?: number;
  created_at: string;
  modified_at: string;
  type: string;
  status: string;
  attachments: Blob[];
  firm_name: string;
  firm: string;
  matter_name: string;
}

enum TaskStatus {
  PENDING = 'Pending',
  BLOCKED = 'Blocked',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  NOT_APPLICABLE = 'Not Applicable',
}

export const TaskStatuses: TaskStatus[] = [
  TaskStatus.PENDING,
  TaskStatus.BLOCKED,
  TaskStatus.IN_PROGRESS,
  TaskStatus.COMPLETED,
  TaskStatus.NOT_APPLICABLE,
];

interface TaskUpdate {
  id: string;
  task: string;
  update: string;
  visibility: string;
  agent: string | null;
  user: string;
  created_at: string;
  modified_at: string;
  attachments: Blob[];
}

interface CreateTaskPayload {
  name: string;
  description?: string;
  status?: TaskStatus;
  due_date?: string;
  order?: number;
}

interface UpdateTaskPayload {
  name?: string | null;
  description?: string | null;
  due_date?: string | null;
  completed_at?: string | null;
  order?: number | null;
  type?: string | null;
  group?: string | null;
  status?: TaskStatus | null;
  attachments?: string[] | null;
}

export enum TaskUpdateVisibility {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
}

interface CreateTaskUpdatePayload {
  update: string;
  visibility: TaskUpdateVisibility;
  attachments?: string[] | null;
}

interface SharedMatter {
  expires_at: string;
  status: string;
}

interface ShareMatterLink {
  token: string;
  matter_id: string;
  email: string;
  created_at: string;
  last_accessed_at: string | null;
  expires_at: string;
}

interface BlobUpdateRequest {
  id: string;
  name?: string;
  collection?: string;
}

interface BlobCreateRequest {
  name: string;
  file: string;
  original_filename: string | null;
  collection: string;
  file_mime_type: string;
}

interface Blob {
  id: string;
  collection_details: CollectionDetails;
  created_at: string;
  modified_at: string;
  name: string;
  original_filename?: string | null;
  download_url: string;
  file_hash: string;
  file_size: number;
  file_extension?: string | null;
  file_mime_type?: string | null;
}

interface CollectionDetails {
  id: string;
  name: string;
  parent_id: string | null;
  matter_id: string;
  depth: number;
  path: string;
  child_count: number;
  blob_count: number;
  created_at: string;
  modified_at: string;
}

interface Collection {
  id: string;
  created_at: string;
  modified_at: string;
  path: string;
  depth: number;
  numchild: number;
  name: string;
}

interface CollectionNode {
  id: string;
  name: string;
  parent_id: string | null;
  matter_id: string;
  depth: number;
  path: string;
  child_count: number;
  blob_count: number;
  created_at: string;
  modified_at: string;
  children?: CollectionNode[];
}

interface Engagement {
  id: string;
  status: string;
  letter_path: string;
  matter: string;
  client: string;
}

interface Conversation {
  id: string;
  format: string;
  name: string;
  transcript_path: string;
  audio_path: string;
  firm_user: string;
  start_timestamp: string;
  end_timestamp: string;
  matter: string;
  client: string;
}

type MatterKeyConsiderationSeverity = 'Green' | 'Yellow' | 'Red' | 'Gray';

export const MatterKeyConsiderationSeverities: MatterKeyConsiderationSeverity[] =
  ['Green', 'Yellow', 'Red', 'Gray'];

export enum MatterKeyConsiderationCategory {
  // Mandatory Flags
  CLEAR_LIABILITY = 'Clear Liability',
  SIGNIFICANT_INJURIES = 'Significant Injuries',
  INSURANCE_COVERAGE = 'Insurance Coverage',

  // Yellow Flags
  POTENTIALLY_TOUGH_CLIENT = 'Potentially Tough Client',
  CLIENT_IS_A_MINOR = 'Client is a Minor',
  HAD_PREVIOUS_REPRESENTATION = 'Had Previous Representation',
  PRE_EXISTING_INJURIES = 'Pre-existing Injuries',
  MINIMAL_PROPERTY_DAMAGE = 'Minimal Property Damage',
  PRIOR_LEGAL_ISSUES = 'Prior Legal Issues',
  SUBSTANCE_USE = 'Substance Use',
  INCONSISTENT_STATEMENTS = 'Inconsistent Statements',
  INSUFFICIENT_EVIDENCE = 'Insufficient Evidence',

  // Red Flags
  CLIENT_CRIMINAL_ACTIVITY = 'Client Criminal Activity',
  EVIDENCE_CONTRADICTS_CLIENT = 'Evidence Contradicts Client',
  STATUTE_OF_LIMITATIONS = 'Statute of Limitations',
  MEDICAL_TREATMENT_DELAYED = 'Medical Treatment Delayed',
  PAST_MEDICAL_HISTORY = 'Past Medical History',
}

export const MandatoryFlags: MatterKeyConsiderationCategory[] = [
  MatterKeyConsiderationCategory.CLEAR_LIABILITY,
  MatterKeyConsiderationCategory.SIGNIFICANT_INJURIES,
  MatterKeyConsiderationCategory.INSURANCE_COVERAGE,
];

interface MatterKeyConsideration {
  id: string;
  category: MatterKeyConsiderationCategory;
  severity: MatterKeyConsiderationSeverity;
  content: string;
}

interface MatterSummary {
  id: string;
  category: 'Accident' | 'Damages';
  content: string;
}

interface ActionItem {
  id: string;
  content: string;
  matter: string;
}

export interface Firm {
  id: string;
  name: string;
}

export enum MatterType {
  MOTOR_VEHICLE_ACCIDENT = 'Motor Vehicle Accident',
  PREMISES_LIABILITY = 'Premises Liability',
  SELLER_NON_DISCLOSURE = 'Seller Non-Disclosure',
  MEDICAL_MALPRACTICE = 'Medical Malpractice',
  UNKNOWN = 'Unknown',
  OTHER = 'Other',
}

interface BaseMatter {
  id: string;
  name: string;
  type: string;
  matter_type?: MatterType;
  status?: string;
  firm: Firm;
  date_of_loss?: string;
}

interface BaseDetailedMatter extends BaseMatter {
  creator?: User;
  engagements: Engagement[];
  clients: MatterClient[];
  conversations: Conversation[];
  key_considerations: MatterKeyConsideration[];
  summaries: MatterSummary[];
  action_items: ActionItem[];
  form?: Form;
  intake_note?: string;
}

type DetailedMatter = BaseDetailedMatter;

type MatterUpdate = Partial<
  Pick<BaseMatter, 'name' | 'matter_type' | 'status'>
> & {
  intake_note?: string;
};

export enum MatterStatus {
  LEAD = 'Lead',
  INTAKE = 'Intake',
  CASE_SETUP = 'Case Setup',
  TREATMENT = 'Treatment',
  DEMAND_PREPARATION = 'Demand Preparation',
  DEMAND_COMPLETE = 'Demand Complete',
  DROPPED = 'Dropped',
  ARCHIVED = 'Archived',
}

export interface CreateMatterRequest {
  firm: string;
  name: string;
  status?: MatterStatus;
  type?: string;
  creator: string;
  clients: { phone_numbers: { phone_number: string }[] }[];
}

export type {
  BaseMatter,
  Blob,
  BlobCreateRequest,
  BlobUpdateRequest,
  Collection,
  CollectionDetails,
  CollectionNode,
  Conversation,
  CreateTaskPayload,
  CreateTaskUpdatePayload,
  DetailedMatter,
  Engagement,
  MatterKeyConsideration,
  MatterKeyConsiderationSeverity,
  MatterSummary,
  MatterUpdate,
  SharedMatter,
  ShareMatterLink,
  Task,
  TaskSearchResults,
  TaskStatus,
  TaskUpdate,
  UpdateTaskPayload,
};
