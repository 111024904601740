import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithErrorHandling } from './baseQuery';
import {
  DetailedMatter,
  SharedMatter,
  ShareMatterLink,
} from '../types/client-intake-types';
import { DocumentInstance } from '../types/letters-types';

export const sharedMatterApiClient = createApi({
  reducerPath: 'sharedMatterApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['SharedMatter', 'SharedMatterStatus'] as const,
  endpoints: (builder) => ({
    getSharedMatter: builder.query<DetailedMatter, string | undefined>({
      query: (token) => `api/shared-matter/${token}/`,
      providesTags: ['SharedMatter'],
    }),
    getSharedMatterDocuments: builder.query<
      DocumentInstance[],
      { token: string }
    >({
      query: ({ token }) => `api/shared-matter/${token}/documents/`,
      providesTags: ['SharedMatter'],
    }),
    acceptSharedMatter: builder.mutation<DetailedMatter, { token: string }>({
      query: ({ token }) => ({
        url: `api/shared-matter/${token}/accept/`,
        method: 'POST',
      }),
      invalidatesTags: ['SharedMatterStatus'],
    }),
    declineSharedMatter: builder.mutation<DetailedMatter, { token: string }>({
      query: ({ token }) => ({
        url: `api/shared-matter/${token}/decline/`,
        method: 'POST',
      }),
      invalidatesTags: ['SharedMatterStatus'],
    }),
    getSharedMatterLinks: builder.query<ShareMatterLink[], string | undefined>({
      query: (matter_id) => `api/shared-matter/${matter_id}/link/`,
      providesTags: ['SharedMatter'],
    }),
    createSharedMatterLink: builder.mutation<
      ShareMatterLink,
      { matter_id: string; email: string }
    >({
      query: ({ matter_id, email }) => ({
        url: `api/shared-matter/${matter_id}/link/`,
        method: 'POST',
        body: {
          email,
        },
      }),
      invalidatesTags: ['SharedMatter'],
    }),
    deleteSharedMatterLink: builder.mutation<
      ShareMatterLink,
      { matter_id: string; email: string }
    >({
      query: ({ matter_id, email }) => ({
        url: `api/shared-matter/${matter_id}/link/`,
        method: 'DELETE',
        body: {
          email,
        },
      }),
      invalidatesTags: ['SharedMatter'],
    }),
    getSharedMatterDocumentPreview: builder.query<
      Blob,
      { token: string; document_id: string }
    >({
      query: ({ token, document_id }) =>
        `api/shared-matter/${token}/documents/${document_id}/preview/`,
    }),
    getSharedMatterStatus: builder.query<SharedMatter, string | undefined>({
      query: (token) => `api/shared-matter/${token}/status/`,
      providesTags: ['SharedMatterStatus'],
    }),
  }),
});

export const {
  useGetSharedMatterQuery,
  useGetSharedMatterLinksQuery,
  useCreateSharedMatterLinkMutation,
  useDeleteSharedMatterLinkMutation,
  useGetSharedMatterDocumentsQuery,
  useGetSharedMatterStatusQuery,
  useAcceptSharedMatterMutation,
  useDeclineSharedMatterMutation,
} = sharedMatterApiClient;
