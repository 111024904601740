import { TextField, Box } from '@mui/material';
import { ClientEmploymentNote } from '../../../services/types/matter-notes.types';
import NoteSection from '../components/NoteSection';
import BaseNoteForm, { useFormContext } from '../components/BaseNoteForm';

const ClientEmploymentFormFields = () => {
  const { handleChange, formData } = useFormContext();

  const inputSx = {
    fontSize: '0.875rem',
    color: 'info.light',
  };

  const labelSx = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: 'text.secondary',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TextField
        size="small"
        label="EMPLOYER"
        name="employer"
        value={formData.employer || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="ROLE"
        name="role"
        value={formData.role || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="WAGE"
        name="wage"
        type="number"
        value={formData.wage || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
    </Box>
  );
};

const ClientEmploymentNoteForm: React.FC<{
  initialData?: ClientEmploymentNote;
  onSubmit: (data: Partial<ClientEmploymentNote>) => void;
  isSubmitting: boolean;
}> = ({ initialData, onSubmit, isSubmitting }) => {
  return (
    <BaseNoteForm
      initialData={initialData}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      expandedFields={['employer', 'role', 'wage']}
      prompt="An explanation of the client's employment status"
    >
      <ClientEmploymentFormFields />
    </BaseNoteForm>
  );
};

const ClientEmploymentNoteSection: React.FC<{ matterId: string }> = ({
  matterId,
}) => {
  return (
    <NoteSection
      matterId={matterId}
      noteType="client-employment"
      title="Employment"
      FormFields={ClientEmploymentNoteForm}
    />
  );
};

export default ClientEmploymentNoteSection;
