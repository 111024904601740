import { Box, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SaveButton from './SaveButton';

interface EditableTextAreaProps {
  value: string;
  onSave: (value: string) => Promise<void>;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  noPadding?: boolean;
  hideBorder?: boolean;
}

const EditableTextArea: React.FC<EditableTextAreaProps> = ({
  value: initialValue,
  onSave,
  minRows = 1,
  maxRows = 20,
  placeholder,
  noPadding = false,
  hideBorder = false,
}) => {
  const [content, setContent] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setContent(initialValue);
    setIsDirty(false); // Reset dirty state when initial value changes
  }, [initialValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    setIsDirty(newContent !== initialValue);
  };

  const handleSave = useCallback(async () => {
    if (content === initialValue || !isDirty) {
      return;
    }

    setIsSubmitting(true);
    try {
      const savePromise = onSave(content);
      if (savePromise) {
        await savePromise;
        setIsDirty(false);
      }
    } catch (error) {
      // If save fails, keep the dirty state
      console.error('Failed to save:', error);
    } finally {
      setIsSubmitting(false);
    }
  }, [content, initialValue, isDirty, onSave]);

  // Add keyboard shortcut handler
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault();
        if (isDirty && !isSubmitting) {
          handleSave();
        }
      }
    },
    [isDirty, isSubmitting, handleSave],
  );

  // Add and remove event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Box
        sx={{
          minHeight: 'auto',
          p: noPadding ? 0 : 3,
          border: hideBorder ? 'none' : '1px solid',
          borderColor: hideBorder ? 'transparent' : 'border.main',
          borderRadius: hideBorder ? 0 : 1,
          bgcolor: 'background.paper',
          transition: 'all 0.2s ease-in-out',
          '&:hover': hideBorder
            ? {}
            : {
                bgcolor: 'background.default',
                borderColor: 'primary.main',
              },
        }}
      >
        <TextField
          fullWidth
          multiline
          minRows={minRows}
          maxRows={maxRows}
          value={content}
          onChange={handleChange}
          placeholder={placeholder}
          sx={{
            height: 'fit-content',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              backgroundColor: 'transparent',
              padding: 0,
            },
            '& .MuiInputBase-input': {
              padding: 0,
              color: 'info.light',
              fontSize: '0.875rem',
              minHeight: '24px',
            },
          }}
          InputProps={{
            sx: {
              height: 'auto',
            },
          }}
          variant="outlined"
        />
      </Box>
      {isDirty && (
        <SaveButton onClick={handleSave} isSubmitting={isSubmitting} />
      )}
    </>
  );
};

export default EditableTextArea;
