import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowDown, ArrowUp, ChevronRightIcon, X as XIcon } from 'lucide-react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useGetTasksQuery, useUpdateTaskMutation } from '@/services/api';
import { cn } from '@/lib/utils';
import {
  Task,
  TaskStatus,
  TaskStatuses,
} from '@/services/types/client-intake-types';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { TaskDetail } from './TaskDetail';
import { TaskStatusBadge } from './TaskStatusBadge';
import { TaskDueDate } from './TaskDueDate';
import { CreateTaskModal } from './CreateTaskModal';

const TaskWarningBadge = ({ task }: { task: Task }) => {
  const isPastDue = task.due_date && new Date(task.due_date) < new Date();

  const isUrgent = false;

  if (!isPastDue && !isUrgent) {
    return null;
  }

  return (
    <span
      className={cn(
        'px-2 py-1 rounded text-xs font-bold',
        isPastDue && 'bg-orange-100 text-orange-700',
        isUrgent && 'bg-purple-100 text-purple-700',
      )}
    >
      {isPastDue && 'PAST DUE'}
      {isUrgent && 'URGENT'}
    </span>
  );
};

const FilterDropdown = <T extends { id: string; name: string }>({
  label,
  options,
  value,
  onChange,
}: {
  label: string;
  options: T[];
  value: T | null;
  onChange: (value: string | null) => void;
}) => {
  return (
    <DropdownMenu>
      <div className="flex flex-row gap-0 justify-center items-stretch cursor-pointer">
        <DropdownMenuTrigger asChild>
          <div
            className={cn(
              'border-gray-400 rounded-sm flex flex-row items-center gap-2 p-2',
              value &&
                'bg-primary text-white hover:bg-primary/90 rounded-r-none',
            )}
          >
            <FilterListIcon />
            <span className="font-semibold pb-1">
              {label}
              {value && `: ${value?.name}`}
            </span>
          </div>
        </DropdownMenuTrigger>
        {value && (
          <Button
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => {
              onChange(null);
            }}
            className="h-[44px] w-[44px] bg-primary hover:bg-primary/90 rounded-r-sm rounded-l-none text-white focus:outline-none focus:ring-primary"
            aria-label={`Clear ${label} filter`}
            asChild
          >
            <XIcon className="p-2 w-4 h-4" />
          </Button>
        )}
      </div>
      <DropdownMenuContent
        align="start"
        className="bg-white rounded-sm border-gray-300"
      >
        {options.map((option) => (
          <DropdownMenuItem
            key={option.id}
            onClick={() => onChange(option.id)}
            className={cn(
              'cursor-pointer mb-1 font-semibold text-sm',
              value?.id === option.id && 'bg-primary/10',
            )}
          >
            {option.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const Tasks: React.FC = () => {
  const navigate = useNavigate();
  const { task_id } = useParams();
  const [params, setParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = React.useState(
    params.get('search') || '',
  );
  const [statusFilter, setStatusFilter] = React.useState<TaskStatus | null>(
    (params.get('status') as TaskStatus) || null,
  );
  const [firmFilter, setFirmFilter] = React.useState<string | null>(
    params.get('matter__firm'),
  );
  const [matterFilter, setMatterFilter] = React.useState<string | null>(
    params.get('matter'),
  );
  const [ordering, setOrdering] = React.useState<
    'due_date' | '-due_date' | null
  >(params.get('ordering') as 'due_date' | '-due_date' | null);
  const [selectedTask, setSelectedTask] = React.useState<Task | null>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  // Get unique firms and matters from tasks for filter options
  const { data: tasks, isLoading: isTasksLoading } = useGetTasksQuery({
    search: searchQuery,
    status: statusFilter || undefined,
    matter: matterFilter || undefined,
    matter__firm: firmFilter || undefined,
    ordering: ordering || 'due_date',
    limit: 100,
  });

  useEffect(() => {
    if (tasks?.results && (selectedTask || task_id)) {
      setSelectedTask(
        tasks.results.find(
          (task) => task.id === (selectedTask?.id || task_id),
        ) || null,
      );
    }
  }, [tasks?.results, selectedTask, task_id]);

  const uniqueFirms = React.useMemo(() => {
    if (!tasks?.results) return [];
    return Array.from(
      new Map(
        tasks.results.map((task) => [
          task.firm,
          { id: task.firm, name: task.firm_name },
        ]),
      ).values(),
    );
  }, [tasks?.results]);

  const uniqueMatters = React.useMemo(() => {
    if (!tasks?.results) return [];
    return Array.from(
      new Map(
        tasks.results.map((task) => [
          task.matter,
          { id: task.matter, name: task.matter_name },
        ]),
      ).values(),
    );
  }, [tasks?.results]);

  // Update URL params when filters change
  React.useEffect(() => {
    const newParams = new URLSearchParams(params);
    if (searchQuery) newParams.set('search', searchQuery);
    else newParams.delete('search');
    if (statusFilter) newParams.set('status', statusFilter);
    else newParams.delete('status');
    if (firmFilter) newParams.set('matter__firm', firmFilter);
    else newParams.delete('matter__firm');
    if (matterFilter) newParams.set('matter', matterFilter);
    else newParams.delete('matter');
    if (ordering) newParams.set('ordering', ordering);
    else newParams.delete('ordering');
    setParams(newParams);
  }, [
    searchQuery,
    statusFilter,
    firmFilter,
    matterFilter,
    ordering,
    params,
    setParams,
  ]);

  const [updateTask] = useUpdateTaskMutation();

  if (isTasksLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-primary border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="space-y-4 m-8">
      <div className="flex items-center gap-3">
        <Input
          placeholder="Filter tasks"
          className="max-w-sm border-gray-400 shadow-none rounded-sm"
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FilterDropdown<{ id: string; name: string }>
          label="Status"
          options={TaskStatuses.map((status) => ({
            id: status,
            name: status,
          }))}
          value={statusFilter ? { id: statusFilter, name: statusFilter } : null}
          onChange={(value) => setStatusFilter(value as TaskStatus)}
        />
        <FilterDropdown<{ id: string; name: string }>
          label="Firm"
          options={uniqueFirms}
          value={
            firmFilter
              ? {
                  id: firmFilter,
                  name:
                    uniqueFirms.find((firm) => firm.id === firmFilter)?.name ||
                    '',
                }
              : null
          }
          onChange={(value) => setFirmFilter(value)}
        />
        <FilterDropdown<{ id: string; name: string }>
          label="Matter"
          options={uniqueMatters}
          value={
            matterFilter
              ? {
                  id: matterFilter,
                  name:
                    uniqueMatters.find((matter) => matter.id === matterFilter)
                      ?.name || '',
                }
              : null
          }
          onChange={(value) => setMatterFilter(value)}
        />
        <Button
          variant="default"
          className="ml-auto text-white font-bold rounded-sm"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Add Task
        </Button>
      </div>
      <Card className="border border-[#0000000F] rounded-md shadow-none pb-1">
        <Table className="border-none rounded-md overflow-hidden">
          <TableHeader className="rounded-md">
            <TableRow className="bg-background-gray-dark border-b-[#0000000F] rounded-md text-[#00000080]">
              <TableHead
                className="cursor-pointer"
                onClick={() => {
                  if (ordering === '-due_date') {
                    setOrdering('due_date');
                  } else {
                    setOrdering('-due_date');
                  }
                }}
              >
                <div className="flex flex-row items-center gap-2">
                  <h3 className="pl-2 font-semibold">Target</h3>
                  {(ordering === null || ordering === 'due_date') && (
                    <ArrowDown className="pt-1" size={16} />
                  )}
                  {ordering === '-due_date' && (
                    <ArrowUp className="pt-1" size={16} />
                  )}
                </div>
              </TableHead>
              <TableHead>
                <h3 className="font-semibold">Task</h3>
              </TableHead>
              <TableHead>
                <h3 className="font-semibold">Matter</h3>
              </TableHead>
              <TableHead>
                <h3 className="font-semibold">Firm</h3>
              </TableHead>
              <TableHead>
                <h3 className="font-semibold">Status</h3>
              </TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {tasks?.results.map((task) => (
              <TableRow
                className={cn(
                  task.status === 'Not Applicable' && 'opacity-50',
                  'cursor-pointer hover:bg-gray-50',
                )}
                key={task.id}
                onClick={() => navigate(`/tasks/${task.id}`)}
              >
                <TableCell className="text-left">
                  <TaskDueDate
                    date={task.due_date}
                    onDateChange={(date) => {
                      if (!date) return;
                      updateTask({
                        matterId: task.matter,
                        taskId: task.id,
                        task: { due_date: date.toISOString() },
                      });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2 h-full">
                    <TaskWarningBadge task={task} />
                    <h3 className="font-bold">{task.name}</h3>
                  </div>
                </TableCell>
                <TableCell className="opacity-50 font-semibold">
                  {task.matter_name}
                </TableCell>
                <TableCell className="opacity-50 font-semibold">
                  {task.firm_name}
                </TableCell>
                <TableCell>
                  <TaskStatusBadge
                    status={task.status}
                    onStatusChange={(status) => {
                      if (!status) {
                        return;
                      }

                      updateTask({
                        matterId: task.matter,
                        taskId: task.id,
                        task: {
                          status: status as TaskStatus,
                          completed_at:
                            status === 'Completed'
                              ? new Date().toISOString()
                              : null,
                        },
                      });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ChevronRightIcon className="p-1 opacity-50" size={24} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {selectedTask && (
        <TaskDetail task={selectedTask} onClose={() => setSelectedTask(null)} />
      )}

      <CreateTaskModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </div>
  );
};

export default Tasks;
