import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import IncidentNoteSection from './sections/IncidentNoteSection';
import InvolvedPersonNoteSection from './sections/InvolvedPersonNoteSection';
import ClientEmploymentNoteSection from './sections/ClientEmploymentNoteSection';
import LawEnforcementNoteSection from './sections/LawEnforcementNoteSection';
import MedicalNoteSection from './sections/MedicalNoteSection';
import InsuranceNoteSection from './sections/InsuranceNoteSection';
import DamageNoteSection from './sections/DamageNoteSection';
import MatterClientNoteSection from './sections/MatterClientNoteSection';
import MatterNotesExtractionButtons from './sections/MatterNotesExtractionButtons';
import { DetailedMatter } from '../../services/types/matter-types';

interface MatterNotesProps {
  matter: DetailedMatter;
}

// Define sections for both navigation and rendering.
const SECTIONS = [
  { id: 'client', label: 'Client', component: MatterClientNoteSection },
  { id: 'incident', label: 'Accident Details', component: IncidentNoteSection },
  {
    id: 'employment',
    label: 'Employment',
    component: ClientEmploymentNoteSection,
  },
  { id: 'medical', label: 'Medicals', component: MedicalNoteSection },
  { id: 'insurance', label: 'Insurance', component: InsuranceNoteSection },
  { id: 'law', label: 'Police', component: LawEnforcementNoteSection },
  { id: 'damage', label: 'Damages', component: DamageNoteSection },
  {
    id: 'involved',
    label: 'Additional Parties',
    component: InvolvedPersonNoteSection,
  },
] as const;

const MatterNotes = ({ matter }: MatterNotesProps): JSX.Element => {
  const [activeSection, setActiveSection] = useState<string>('incident');

  const scrollToSection = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Update active section based on scroll position
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting, target }) => {
          if (isIntersecting) {
            setActiveSection(target.id);
          }
        });
      },
      {
        rootMargin: '-20% 0px -75% 0px', // Triggers when section is in top quarter of viewport
      },
    );

    SECTIONS.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {/* Sidebar Navigation */}
      <Box
        sx={{
          position: 'sticky',
          top: 16,
          height: 'fit-content',
          p: 2,
          pr: 6,
          minWidth: '120px',
          alignSelf: 'flex-start',
        }}
      >
        <Box>
          {SECTIONS.map((section) => (
            <Box
              key={section.id}
              data-section={section.id}
              onClick={() => scrollToSection(section.id)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'relative',
                pl: 2,
                height: '24px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  color:
                    activeSection === section.id
                      ? 'primary.main'
                      : 'text.secondary',
                  transition: 'color 0.2s',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                {section.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {/* Main Content */}
      <Box sx={{ flex: 1 }}>
        <MatterNotesExtractionButtons matterId={matter.id} />
        {matter.clients && (
          <MatterClientNoteSection
            client={matter.clients[0]}
            matterId={matter.id}
          />
        )}
        {SECTIONS.filter((section) => section.id !== 'client').map(
          ({ id, component: Component }) => (
            <Box key={id} id={id}>
              <Component matterId={matter.id} />
            </Box>
          ),
        )}
      </Box>
    </Box>
  );
};

export default MatterNotes;
