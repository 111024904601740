import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnFiltersState } from '@tanstack/react-table';

interface MatterState {
  globalFilter: string;
  columnFilters: ColumnFiltersState;
}

const initialState: MatterState = {
  globalFilter: '',
  columnFilters: [],
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setGlobalFilter(state, action: PayloadAction<string>) {
      state.globalFilter = action.payload;
    },
    setColumnFilters(state, action: PayloadAction<ColumnFiltersState>) {
      state.columnFilters = action.payload;
    },
  },
});

export const { setGlobalFilter, setColumnFilters } = clientsSlice.actions;

export default clientsSlice.reducer;
