import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Article } from '@mui/icons-material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import {
  Task,
  TaskStatus,
  TaskUpdateVisibility,
} from '@/services/types/client-intake-types';
import {
  useCreateTaskUpdateMutation,
  useGetTaskUpdatesQuery,
  useUpdateTaskMutation,
} from '@/services/api';
import { Dialog, RightDialogContent } from '@/components/ui/dialog';
import { TaskStatusBadge } from './TaskStatusBadge';
import { TaskDueDate } from './TaskDueDate';
import { Checkbox } from '../ui/checkbox';

interface TaskDetailProps {
  task: Task;
  onClose: () => void;
}

// Custom hook for debouncing
const useDebounce = <T,>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const TaskDetail: React.FC<TaskDetailProps> = ({ task, onClose }) => {
  const navigate = useNavigate();
  const [updateTask] = useUpdateTaskMutation();
  const [newUpdate, setNewUpdate] = React.useState('');
  const [internalNotes, setInternalNotes] = React.useState(
    task.description || '',
  );
  const debouncedNotes = useDebounce(internalNotes, 500);
  const { data: taskUpdates } = useGetTaskUpdatesQuery({
    matterId: task.matter,
    taskId: task.id,
  });
  const [createTaskUpdate] = useCreateTaskUpdateMutation();
  const [newUpdateVisibility, setNewUpdateVisibility] =
    React.useState<TaskUpdateVisibility>(TaskUpdateVisibility.PRIVATE);

  const handleAddUpdate = async () => {
    if (!newUpdate.trim()) return;

    await createTaskUpdate({
      matterId: task.matter,
      taskId: task.id,
      update: {
        update: newUpdate,
        visibility: newUpdateVisibility,
      },
    });

    setNewUpdate('');
  };

  // Update task description when debounced value changes
  React.useEffect(() => {
    if (debouncedNotes !== task.description) {
      updateTask({
        matterId: task.matter,
        taskId: task.id,
        task: { description: debouncedNotes },
      });
    }
  }, [debouncedNotes, task.matter, task.id, task.description, updateTask]);

  return (
    <Dialog
      open
      onOpenChange={() => {
        onClose();
        navigate('/tasks');
      }}
    >
      <RightDialogContent className="bg-white px-8 h-[95vh] overflow-y-scroll flex flex-col gap-4">
        <div className="flex justify-between items-start mb-2">
          <div className="flex flex-col gap-1 w-full pt-4">
            <div className="flex items-center justify-between gap-4 w-full">
              <h2 className="text-xl font-semibold">{task.name}</h2>
              <TaskStatusBadge
                status={task.status}
                onStatusChange={(status) => {
                  if (!status) {
                    return;
                  }

                  updateTask({
                    matterId: task.matter,
                    taskId: task.id,
                    task: {
                      status: status as TaskStatus,
                      completed_at:
                        status === 'Completed'
                          ? new Date().toISOString()
                          : null,
                    },
                  });
                }}
              />
            </div>
            <div className="flex items-center gap-2">
              <a
                href={`/matters/${task.matter}`}
                className="text-primary hover:underline font-bold"
              >
                {task.matter_name}
                <OpenInNewIcon className="ml-2 inline-block" />
              </a>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mb-2">
          <div className="flex flex-col items-left gap-3">
            <div
              id="start-date-label"
              className="text-sm text-gray-500 font-bold"
            >
              CREATED
            </div>
            <div>{new Date(task.created_at).toLocaleDateString()}</div>
          </div>
          <div className="flex flex-col items-center gap-2">
            <span
              id="target-date-label"
              className="text-sm text-neutral-500 font-bold"
            >
              TARGET
            </span>
            <TaskDueDate
              date={task.due_date}
              onDateChange={(date) => {
                if (!date) return;
                updateTask({
                  matterId: task.matter,
                  taskId: task.id,
                  task: { due_date: date.toISOString() },
                });
              }}
            />
          </div>
          <div className="flex flex-col items-right gap-3">
            <div
              id="completed-date-label"
              className="text-sm text-gray-500 text-right font-bold"
            >
              COMPLETED
            </div>
            <div
              aria-labelledby="completed-date-label"
              className="font-medium text-right"
            >
              {task.completed_at
                ? new Date(task.completed_at).toLocaleDateString()
                : 'Not set'}
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">Attachments</h3>
          {task.attachments.length === 0 && (
            <div className="border-2 border-dashed rounded-lg p-8 text-center text-gray-500">
              Drop attachments here
            </div>
          )}
          {task.attachments.map((attachment) => (
            <div
              key={attachment.id}
              className="flex items-center justify-between p-2 rounded-lg mb-2"
            >
              <div className="flex items-center gap-2">
                <Article className="h-4 w-4 text-gray-500" />
                <div>
                  <div className="font-medium pl-2 pb-0.5">
                    {attachment.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-2 w-full">
          <h3 className="text-lg font-semibold mb-4">Task Updates</h3>
          <div className="space-y-4">
            {taskUpdates?.map((update) => (
              <div
                key={update.id}
                className="mt-4 flex flex-row gap-8 items-top border-b pb-4"
              >
                <div className="flex justify-between text-sm text-gray-500 font-semibold">
                  <div>{new Date(update.created_at).toLocaleDateString()}</div>
                </div>
                <div className="flex flex-col gap-2">
                  <div>{update.update}</div>
                  {update.attachments.length > 0 && (
                    <div className="flex flex-row gap-2">
                      {update.attachments.map((attachment) => (
                        <div
                          className="text-xs text-gray-500 border border-gray-400 p-2 rounded-md"
                          key={attachment.id}
                        >
                          <div className="flex flex-row gap-2 items-center">
                            <Article className="h-4 w-4 text-gray-500" />
                            {attachment.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {update.visibility === 'Private' && (
                    <div className="flex flex-row gap-2 items-center">
                      <div>
                        <VisibilityOffIcon className="w-2 h-2" />
                      </div>
                      <div className="text-xs font-gray-500 font-semibold">
                        Not visible to attorneys
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div>
              <div className="flex flex-row justify-start gap-14 mb-2">
                <div className="text-sm text-gray-500 font-semibold">Today</div>
                <div className="flex flex-col">
                  <Textarea
                    id="new-update"
                    aria-label="New task update"
                    placeholder="Enter new update..."
                    value={newUpdate}
                    onChange={(e) => setNewUpdate(e.target.value)}
                    className="mb-2 w-xs"
                  />
                  <div className="flex flex-row gap-4 justify-between">
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        className="data-[state=checked]:bg-white data-[state=checked]:border-black"
                        id="private-toggle"
                        checked={
                          newUpdateVisibility === TaskUpdateVisibility.PRIVATE
                        }
                        onCheckedChange={() =>
                          setNewUpdateVisibility(
                            newUpdateVisibility === TaskUpdateVisibility.PRIVATE
                              ? TaskUpdateVisibility.PUBLIC
                              : TaskUpdateVisibility.PRIVATE,
                          )
                        }
                      />
                      <Label htmlFor="private-toggle">
                        <span className="font-bold">Private</span>
                      </Label>
                    </div>
                    <Button onClick={handleAddUpdate}>
                      <span className="font-bold text-white">Add Update</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">Internal Notes</h3>
          <Textarea
            id="internal-notes"
            aria-label="Internal notes"
            placeholder="Type internal Finch notes here..."
            className="h-48"
            value={internalNotes}
            onChange={(e) => setInternalNotes(e.target.value)}
          />
        </div>
      </RightDialogContent>
    </Dialog>
  );
};
