import { TextField, Box } from '@mui/material';
import { InvolvedPersonNote } from '../../../services/types/matter-notes.types';
import BaseNoteForm, { useFormContext } from '../components/BaseNoteForm';
import NoteSection from '../components/NoteSection';
import { useFormFieldStyles } from '../hooks/useFormFieldStyles';

const InvolvedPersonFormFields = () => {
  const { handleChange, formData } = useFormContext();
  const { defaultFieldProps } = useFormFieldStyles();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <TextField
        {...defaultFieldProps}
        label="NAME"
        name="name"
        value={formData.name || ''}
        onChange={handleChange}
      />
      <TextField
        {...defaultFieldProps}
        label="PHONE"
        name="phone"
        value={formData.phone || ''}
        onChange={handleChange}
      />
      <TextField
        {...defaultFieldProps}
        label="EMAIL"
        name="email"
        value={formData.email || ''}
        onChange={handleChange}
      />
    </Box>
  );
};

const InvolvedPersonForm: React.FC<{
  initialData?: InvolvedPersonNote;
  onSubmit: (data: Partial<InvolvedPersonNote>) => void;
  isSubmitting: boolean;
  onDelete?: () => void;
}> = ({ initialData, onSubmit, isSubmitting, onDelete }) => {
  return (
    <BaseNoteForm
      initialData={initialData}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      expandedFields={['name', 'phone', 'email']}
      textRows={2}
      onDelete={onDelete}
      prompt="An additional person involved in the matter"
    >
      <InvolvedPersonFormFields />
    </BaseNoteForm>
  );
};

const InvolvedPersonNoteSection = ({ matterId }: { matterId: string }) => {
  return (
    <NoteSection
      matterId={matterId}
      noteType="involved-person"
      title="People"
      FormFields={InvolvedPersonForm}
    />
  );
};

export default InvolvedPersonNoteSection;
