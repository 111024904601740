import { Box, Button } from '@mui/material';
import React from 'react';

interface SaveButtonProps {
  onClick: () => void;
  isSubmitting: boolean;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, isSubmitting }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      disabled={isSubmitting}
      title={`Save (${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+Enter)`}
      sx={{
        mt: 2,
        borderRadius: 2,
        textTransform: 'none',
        fontSize: '0.875rem',
        px: 3,
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'common.white',
          '& .shortcut-text': {
            color: 'inherit',
            opacity: 0.7,
          },
        },
      }}
    >
      {isSubmitting ? (
        'Saving...'
      ) : (
        <>
          Save
          <Box
            component="span"
            className="shortcut-text"
            sx={{
              ml: 1,
              fontSize: '0.75rem',
              opacity: 0.5,
              color: 'text.secondary',
              transition: 'all 0.2s ease',
            }}
          >
            {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+↵
          </Box>
        </>
      )}
    </Button>
  );
};

export default SaveButton;
