import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { DeleteConfirmationDialogProps } from '../types';

export const DeleteConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  itemCount,
  itemName,
}: DeleteConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>
          {itemCount === 1 && itemName
            ? `Are you sure you want to delete "${itemName}"?`
            : `Are you sure you want to delete ${itemCount} selected files?`}
        </Typography>
        <Typography color="error" sx={{ mt: 2 }} variant="body2">
          This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
