import { createApi } from '@reduxjs/toolkit/query/react';
import {
  NoteCreateArg,
  NoteDeleteArg,
  NoteType,
  NoteTypeToModel,
  NoteUpdateArg,
  isOneToOneNoteType,
} from '../types/matter-notes.types';
import { baseQueryWithErrorHandling } from './baseQuery';

// Helper to generate endpoint paths
const getNoteEndpoint = (matterId: string, noteType: NoteType) =>
  `api/matters/${matterId}/notes/${noteType}`;

export const matterNotesApi = createApi({
  reducerPath: 'matterNotesApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Notes'],
  endpoints: (builder) => ({
    // Generic GET endpoints for all note types
    getNotes: builder.query<
      NoteTypeToModel[NoteType][],
      { matterId: string; noteType: NoteType }
    >({
      query: ({ matterId, noteType }) =>
        `${getNoteEndpoint(matterId, noteType)}/`,
      providesTags: (result, error, { matterId, noteType }) => [
        { type: 'Notes', id: `${matterId}-${noteType}` },
      ],
    }),

    // GET single note - only for one-to-one relationships
    getOneToOneNote: builder.query<
      NoteTypeToModel[NoteType],
      { matterId: string; noteType: NoteType }
    >({
      query: ({ matterId, noteType }) => {
        if (!isOneToOneNoteType(noteType)) {
          throw new Error(
            `Note type ${noteType} is not a one-to-one relationship`,
          );
        }
        return `${getNoteEndpoint(matterId, noteType)}/`;
      },
      providesTags: (result, error, { matterId, noteType }) => [
        { type: 'Notes', id: `${matterId}-${noteType}` },
      ],
    }),

    // CREATE endpoint for all note types
    createNote: builder.mutation<
      NoteTypeToModel[NoteType],
      NoteCreateArg<NoteType>
    >({
      query: ({ matterId, noteType, note }) => ({
        url: `${getNoteEndpoint(matterId, noteType)}/`,
        method: 'POST',
        body: note,
      }),
      async onQueryStarted(
        { matterId, noteType },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: newNote } = await queryFulfilled;

          // Optimistically update the cache
          const patchResult = dispatch(
            matterNotesApi.util.updateQueryData(
              'getNotes',
              { matterId, noteType },
              (draft) => {
                if (!draft) return;
                draft.unshift(newNote);
              },
            ),
          );

          try {
            await queryFulfilled;
          } catch {
            // If the mutation fails, undo the optimistic update
            patchResult.undo();
          }
        } catch {
          // Error is handled by the component
        }
      },
    }),

    // UPDATE endpoint for all note types
    updateNote: builder.mutation<
      NoteTypeToModel[NoteType],
      NoteUpdateArg<NoteType>
    >({
      query: ({ matterId, noteType, noteId, note }) => ({
        url: `${getNoteEndpoint(matterId, noteType)}/${noteId}/`,
        method: 'PATCH',
        body: note,
      }),
      async onQueryStarted(
        { matterId, noteType, noteId, note },
        { dispatch, queryFulfilled },
      ) {
        // Optimistically update the cache
        const patchResult = dispatch(
          matterNotesApi.util.updateQueryData(
            'getNotes',
            { matterId, noteType },
            (draft) => {
              if (!draft) return;
              const index = draft.findIndex((n) => n.id === noteId);
              if (index !== -1) {
                draft[index] = { ...draft[index], ...note };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, undo the optimistic update
          patchResult.undo();
        }
      },
    }),

    // DELETE endpoint for many-to-one relationships only
    deleteNote: builder.mutation<void, NoteDeleteArg & { noteType: NoteType }>({
      query: ({ matterId, noteType, noteId }) => {
        if (isOneToOneNoteType(noteType)) {
          throw new Error(`Cannot delete one-to-one note type ${noteType}`);
        }
        return {
          url: `${getNoteEndpoint(matterId, noteType)}/${noteId}/`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(
        { matterId, noteType, noteId },
        { dispatch, queryFulfilled },
      ) {
        // Optimistically update the cache
        const patchResult = dispatch(
          matterNotesApi.util.updateQueryData(
            'getNotes',
            { matterId, noteType },
            (draft) => {
              if (!draft) return;
              const index = draft.findIndex((n) => n.id === noteId);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, undo the optimistic update
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetNotesQuery,
  useGetOneToOneNoteQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = matterNotesApi;
