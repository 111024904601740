import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DOCUMENT_INSTANCE_STATUS_DISPLAY_NAMES,
  DocumentInstance,
} from '../../../services/types/letters-types';
import { ReadOnlyPreviewLetter } from '../letters/ReadOnlyPreviewLetter';
import { useGetSharedMatterDocumentsQuery } from '../../../services/api';

const ReadOnlyLetterCard = ({ letter }: { letter: DocumentInstance }) => {
  const isContract = letter.document_template.requires_signatures;
  const [showPreview, setShowPreview] = useState(false);
  const TemplateIcon = isContract ? (
    <Tooltip title="Contract - Requires signatures">
      <HistoryEduIcon color="primary" />
    </Tooltip>
  ) : (
    <Tooltip title="Letter">
      <ArticleIcon color="primary" />
    </Tooltip>
  );

  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={0} alignItems="flex-start">
          <Stack direction="row" spacing={2} alignItems="center">
            {TemplateIcon}
            <Typography variant="h3">
              {letter.document_template.name}
            </Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {DOCUMENT_INSTANCE_STATUS_DISPLAY_NAMES[letter.status]}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button variant="contained" onClick={() => setShowPreview(true)}>
            Preview
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ width: '100%' }} />
      <ReadOnlyPreviewLetter
        open={showPreview}
        onClose={() => setShowPreview(false)}
        document={letter}
      />
    </>
  );
};

const ReadOnlyLetters: React.FC = () => {
  const { token } = useParams();
  const { data: documents } = useGetSharedMatterDocumentsQuery(
    {
      token: token || '',
    },
    { skip: !token },
  );

  return (
    <Stack direction="column" spacing={4}>
      <Box>
        <Typography variant="h2">Letters</Typography>
        <Divider sx={{ width: '100%', marginTop: 3 }} />
      </Box>

      <Stack spacing={3}>
        {documents && documents.length > 0 ? (
          documents.map((letter) => (
            <ReadOnlyLetterCard key={letter.id} letter={letter} />
          ))
        ) : (
          <Typography variant="body1">No letters found</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ReadOnlyLetters;
