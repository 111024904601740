// Document Templates
export enum DocumentTemplateType {
  ENGAGEMENT_LETTER = 'ENGAGEMENT_LETTER',
  LETTER_OF_REPRESENTATION = 'LETTER_OF_REPRESENTATION',
  FIRST_PARTY_LETTER_OF_REPRESENTATION = 'FIRST_PARTY_LETTER_OF_REPRESENTATION',
  THIRD_PARTY_LETTER_OF_REPRESENTATION = 'THIRD_PARTY_LETTER_OF_REPRESENTATION',
  FIRST_PARTY_LETTER_OF_REPRESENTATION_HEALTH_INSURANCE = 'FIRST_PARTY_LETTER_OF_REPRESENTATION_HEALTH_INSURANCE',
  JOINT_VENTURE_NOTICE = 'JOINT_VENTURE_NOTICE',
}

export const DOCUMENT_TEMPLATE_TYPE_DISPLAY_NAMES: Record<
  DocumentTemplateType,
  string
> = {
  [DocumentTemplateType.ENGAGEMENT_LETTER]: 'Engagement Letter',
  [DocumentTemplateType.LETTER_OF_REPRESENTATION]: 'Letter of Representation',
  [DocumentTemplateType.FIRST_PARTY_LETTER_OF_REPRESENTATION]:
    'First Party Letter of Representation',
  [DocumentTemplateType.THIRD_PARTY_LETTER_OF_REPRESENTATION]:
    'Third Party Letter of Representation',
  [DocumentTemplateType.FIRST_PARTY_LETTER_OF_REPRESENTATION_HEALTH_INSURANCE]:
    'First Party Letter of Representation - Health Insurance',
  [DocumentTemplateType.JOINT_VENTURE_NOTICE]: 'Joint Venture Notice',
};

export enum DocumentInstanceStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED',
  VOIDED = 'VOIDED',
  SIGNED = 'SIGNED',
}

export const DOCUMENT_INSTANCE_STATUS_DISPLAY_NAMES: Record<
  DocumentInstanceStatus,
  string
> = {
  [DocumentInstanceStatus.CREATED]: 'Created',
  [DocumentInstanceStatus.SENT]: 'Sent',
  [DocumentInstanceStatus.COMPLETED]: 'Completed',
  [DocumentInstanceStatus.VOIDED]: 'Voided',
  [DocumentInstanceStatus.SIGNED]: 'Signed by all parties',
};

export interface DocumentTemplate {
  id: string;
  name: string;
  template_type: DocumentTemplateType;
  requires_signatures: boolean;
}

export interface DocumentTemplateField {
  id: string;
  display_name?: string;
  mapping: string;
  default_value: string;
  external_reference: string;
  type: string;
  is_required: boolean;
  document_template: string;
}

export interface DocumentTemplateFields {
  fields: DocumentTemplateField[];
}

export interface DocumentCreationRequest {
  document_template_id: string;
  merge_fields: Record<string, string>;
}

export interface DocumentInstance {
  id: string;
  status: DocumentInstanceStatus;
  external_document_reference: string;
  document_template: DocumentTemplate;
}
