import {
  Button,
  Menu,
  MenuItem,
  Select,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Theme,
  Typography,
  styled,
} from '@mui/material';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const StyledTable = styled(Table)({
  minWidth: 650,
});

export const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.backgroundGray.light,
}));

const CommonHeaderCellStyles = (theme: Theme) => ({
  borderBottom: `1px solid rgba(0, 0, 0, 0.06)`,
  '&:first-of-type': {
    paddingLeft: theme.spacing(6),
  },
  '&:last-of-type': {
    width: 10,
    paddingRight: theme.spacing(6),
    opacity: 1,
  },
});

export const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  opacity: 0.5,
  ...theme.typography.h3,
  ...CommonHeaderCellStyles(theme),
}));

export const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
}));

export const StyledBodyCell = styled(TableCell)(({ theme }) => ({
  ...CommonHeaderCellStyles(theme),
}));

export const StyledCellTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 600,
  opacity: 0.5,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: '33.75rem',
  width: '100%',

  '& .MuiInputBase-input': {
    height: '28px',
    padding: theme.spacing(2),
  },

  '& .MuiInputBase-input::placeholder': {
    opacity: 1,
  },
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  ...theme.typography.h3,
  textTransform: 'none',
  padding: '10px 16px',
}));

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    padding: 4,
    borderRadius: 8,
  },

  '& .MuiList-root': {
    padding: 0,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 8,
  borderRadius: 8,
  fontSize: '0.875rem',
}));

export const StyledSelect = styled(Select)({
  minWidth: 120,
  '& .MuiSelect-select': {
    padding: '4px 8px',
  },
});

export const StyledActionButton = styled(Button)({
  width: 24,
  minWidth: 24,
  height: 24,
  opacity: 0.25,
  color: 'inherit',
});

export const StyledFilterHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));
