import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { DetailedMatter } from '../../../services/types/client-intake-types';
import ClientScreenTile from '../../base/ClientScreenTile';

interface ReadOnlySummariesProps {
  matter: DetailedMatter;
}

const SummaryItem: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <Grid item xs={12} sm={12} lg={4} key={title}>
      <Typography variant="body1" sx={{ mb: '8px' }}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

const IntakeNotes: React.FC<{ matter: DetailedMatter | undefined }> = ({
  matter,
}) => {
  return (
    <SummaryItem title="Intake Notes">
      <Typography variant="body2" color="info.light">
        {matter?.intake_note || ''}
      </Typography>
    </SummaryItem>
  );
};

const ReadOnlySummaries: React.FC<ReadOnlySummariesProps> = ({ matter }) => {
  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2} sx={{ margin: 'initial' }}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 3,
          }}
          justifyContent="space-between"
          display="flex"
          alignItems="bottom"
        >
          <Typography variant="h2">Case Summary</Typography>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            {matter.summaries &&
              matter.summaries.map((summary) => (
                <SummaryItem key={summary.id} title={summary.category}>
                  <Typography variant="body2" color="info.light">
                    {summary.content}
                  </Typography>
                </SummaryItem>
              ))}
            <IntakeNotes matter={matter} />
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default ReadOnlySummaries;
