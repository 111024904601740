import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Blob,
  BlobCreateRequest,
  BlobUpdateRequest,
  CollectionNode,
} from '../types/client-intake-types';

import { baseQueryWithErrorHandling } from './baseQuery';

export const collectionsApiClient = createApi({
  reducerPath: 'collectionsApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['CollectionTree', 'Blobs'] as const,
  endpoints: (builder) => ({
    getBlobsForCollection: builder.query<Blob[], string>({
      query: (matter_id) => `api/matters/${matter_id}/blobs/`,
      providesTags: (result, error, matter_id) => [
        { type: 'Blobs', id: matter_id },
      ],
    }),
    getCollectionTree: builder.query<CollectionNode, string>({
      query: (matterId) => `api/matters/${matterId}/collections/tree/`,
      providesTags: (result, error, matter_id) => [
        { type: 'CollectionTree', id: matter_id },
      ],
    }),
    createBlob: builder.mutation<
      Blob,
      { matterId: string; blob: BlobCreateRequest }
    >({
      query: ({ matterId, blob }) => ({
        url: `api/matters/${matterId}/blobs/`,
        method: 'POST',
        body: blob,
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'Blobs', id: matterId },
      ],
    }),
    destroyBlob: builder.mutation<void, { matterId: string; blobId: string }>({
      query: ({ matterId, blobId }) => ({
        url: `api/matters/${matterId}/blobs/${blobId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'Blobs', id: matterId },
      ],
    }),
    updateBlob: builder.mutation<
      Blob,
      { matterId: string; blob: BlobUpdateRequest; blobId: string }
    >({
      query: ({ matterId, blob, blobId }) => ({
        url: `api/matters/${matterId}/blobs/${blobId}/`,
        method: 'PUT',
        body: blob,
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'Blobs', id: matterId },
      ],
    }),
    createChildCollection: builder.mutation<
      CollectionNode,
      { matterId: string; parentId: string; name: string }
    >({
      query: ({ matterId, parentId, name }) => ({
        url: `/api/matters/${matterId}/collections/${parentId}/create_child/`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'CollectionTree', id: matterId },
      ],
    }),
    updateCollection: builder.mutation<
      CollectionNode,
      { matterId: string; collectionId: string; name: string }
    >({
      query: ({ matterId, collectionId, name }) => ({
        url: `/api/matters/${matterId}/collections/${collectionId}/`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'CollectionTree', id: matterId },
      ],
    }),
    destroyCollection: builder.mutation<
      void,
      { matterId: string; collectionId: string }
    >({
      query: ({ matterId, collectionId }) => ({
        url: `/api/matters/${matterId}/collections/${collectionId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { matterId }) => [
        { type: 'CollectionTree', id: matterId },
        { type: 'Blobs', id: matterId },
      ],
    }),
  }),
});

export const {
  useGetCollectionTreeQuery,
  useGetBlobsForCollectionQuery,
  useCreateBlobMutation,
  useDestroyBlobMutation,
  useUpdateBlobMutation,
  useCreateChildCollectionMutation,
  useUpdateCollectionMutation,
  useDestroyCollectionMutation,
} = collectionsApiClient;
