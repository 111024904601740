import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Stack,
  TableHead,
  TextField,
  Typography,
} from '@mui/material';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  Row,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BaseMatter } from '../../services/types/client-intake-types';
import { setColumnFilters, setGlobalFilter } from '../../state/reducers/matter';
import {
  selectColumnFilters,
  selectGlobalFilter,
} from '../../state/selectors/matter-selectors';
import { CreateMatterModal } from '../sidebar/CreateMatter';
import { useColumns } from './MattersTable.config';
import {
  StyledActionButton,
  StyledAddButton,
  StyledFilterHeader,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledMenu,
  StyledTable,
  StyledTableContainer,
  StyledTextField,
} from './MattersTable.styles';
import './styles.css';

interface FilterMenuProps {
  table: Table<BaseMatter>;
}

const FilterMenu = ({ table }: FilterMenuProps) => {
  const dispatch = useDispatch();
  const filterableColumns = table
    .getAllColumns()
    .filter((column) => column.getCanFilter());

  const handleClearAll = () => {
    dispatch(setColumnFilters([]));
  };

  const uniqueValues: Record<string, string[]> = useMemo(() => {
    return Object.fromEntries(
      filterableColumns.map((column) => {
        return [
          column.id,
          Array.from(column.getFacetedUniqueValues().keys()).sort(),
        ];
      }),
    );
  }, [filterableColumns]);

  return (
    <Stack spacing={2} px={2} py={1} minWidth={300}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">Filters</Typography>
        <Button onClick={handleClearAll} variant="text" size="small">
          Clear All
        </Button>
      </Stack>
      <Divider />
      {filterableColumns.map((column) => {
        const currentValues = (column.getFilterValue() as string[]) || [];

        return (
          <Stack key={column.id} spacing={3}>
            <StyledFilterHeader>
              <Typography variant="h3" sx={{ opacity: 0.5 }}>
                {column.columnDef.header?.toString()}
              </Typography>
            </StyledFilterHeader>
            <Autocomplete
              multiple
              size="small"
              options={uniqueValues[column.id]}
              value={currentValues}
              popupIcon={null}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                const newFilters = table
                  .getState()
                  .columnFilters.filter((filter) => filter.id !== column.id);
                if (newValue.length > 0) {
                  newFilters.push({
                    id: column.id,
                    value: newValue,
                  });
                }
                dispatch(setColumnFilters(newFilters));
              }}
              renderInput={(params) => (
                <TextField
                  slotProps={{
                    htmlInput: params?.inputProps,
                    input: params?.InputProps,
                  }}
                  {...params}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size="small"
                  />
                ))
              }
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export const MattersTableActionButton = ({
  table,
}: {
  table: Table<BaseMatter>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // At some point we may want to only close the menu if the user clicks outside of it
    setAnchorEl(null);
  };

  return (
    <>
      <StyledActionButton
        variant="text"
        size="small"
        disableRipple
        onClick={handleClick}
        aria-controls={open ? 'matter-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <FilterListIcon />
      </StyledActionButton>
      <StyledMenu
        id="matter-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <FilterMenu table={table} />
      </StyledMenu>
    </>
  );
};

interface MattersTableHeaderProps {
  table: Table<BaseMatter>;
  onAddMatter: () => void;
}

const MattersTableHeader = ({
  table,
  onAddMatter,
}: MattersTableHeaderProps) => {
  const searchQuery = useSelector(selectGlobalFilter);
  const dispatch = useDispatch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch(setGlobalFilter(value));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={6}
      pt={4}
      bgcolor={(theme) => theme.palette.backgroundGray.light}
    >
      <Typography variant="h1" flexGrow={1}>
        Matters
      </Typography>

      <Stack
        flexGrow={2}
        direction="row"
        alignItems="center"
        spacing={4}
        justifyContent="flex-end"
      >
        <StyledTextField
          value={searchQuery}
          onChange={handleSearch}
          slotProps={{
            input: {
              startAdornment: <SearchIcon />,
            },
          }}
          placeholder="Search matters"
        />
        <StyledAddButton
          variant="contained"
          color="primary"
          onClick={onAddMatter}
        >
          Add Matter
        </StyledAddButton>
        <MattersTableActionButton table={table} />
      </Stack>
    </Stack>
  );
};

const MatterTableRow = memo(
  ({
    row,
    onViewDetails,
  }: {
    row: Row<BaseMatter>;
    onViewDetails: (matter: BaseMatter) => void;
  }) => {
    return (
      <tr
        key={row.id}
        style={{
          height: 48,
          padding: 16,
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        }}
      >
        {row.getVisibleCells().map((cell) => (
          <td
            {...(cell.column.id === 'name' && {
              onClick: () => onViewDetails(row.original),
              style: { cursor: 'pointer', padding: 16 },
            })}
            style={{ padding: 16 }}
            key={cell.id}
          >
            {cell.column.id === 'actions'
              ? flexRender(cell.column.columnDef.cell, cell.getContext())
              : (cell.getValue() as string)}
          </td>
        ))}
      </tr>
    );
  },
  (prevProps, nextProps) => prevProps.row.id === nextProps.row.id,
);

MatterTableRow.displayName = 'MatterTableRow';
interface MattersTableProps {
  data: BaseMatter[];
}

export const MattersTable = ({ data }: MattersTableProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalFilter = useSelector(selectGlobalFilter);
  const columnFilters = useSelector(selectColumnFilters);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const fuzzyFilter: FilterFn<BaseMatter> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the ranking info
    addMeta(itemRank);

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const onViewDetails = (matter: BaseMatter) => {
    navigate(`/matters/${matter.id}`);
  };

  const columns = useColumns({ onViewDetails });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      globalFilter,
      columnFilters,
    },
    onGlobalFilterChange: (value) => dispatch(setGlobalFilter(value)),
    onColumnFiltersChange: (value) => {
      if (Array.isArray(value)) {
        dispatch(setColumnFilters(value));
      }
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    enableGlobalFilter: true,
  });

  return (
    <Stack>
      <MattersTableHeader
        table={table}
        onAddMatter={() => setIsCreateModalOpen(true)}
      />
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <StyledHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <StyledHeaderCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </StyledHeaderCell>
                ))}
              </StyledHeaderRow>
            ))}
          </TableHead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <MatterTableRow
                key={row.id}
                row={row}
                onViewDetails={onViewDetails}
              />
            ))}
          </tbody>
        </StyledTable>
      </StyledTableContainer>
      <CreateMatterModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
      />
    </Stack>
  );
};
