import { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Alert,
} from '@mui/material';
import { MoreVert, Edit, DriveFileMove, Delete } from '@mui/icons-material';
import {
  useUpdateBlobMutation,
  useDestroyBlobMutation,
} from '../../../../services/api/collectionService';
import { BlobActionsProps } from '../types';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { CollectionNode } from '../../../../services/types/client-intake-types';

export const BlobActions = ({
  blob,
  collectionTree,
  onSuccess,
  setSelectedCollectionId,
}: BlobActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newName, setNewName] = useState(blob.name);
  const [moveToCollectionId, setMoveToCollectionId] = useState(
    blob.collection_details.id,
  );
  const [error, setError] = useState<string | null>(null);

  const [updateBlob] = useUpdateBlobMutation();
  const [destroyBlob] = useDestroyBlobMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRename = async () => {
    try {
      await updateBlob({
        matterId: collectionTree.matter_id,
        blob: {
          id: blob.id,
          name: newName,
        },
        blobId: blob.id,
      }).unwrap();
      setRenameDialogOpen(false);
      onSuccess();
    } catch (e) {
      setError('Failed to rename file');
    }
  };

  const handleMove = async () => {
    try {
      await updateBlob({
        matterId: collectionTree.matter_id,
        blob: {
          id: blob.id,
          collection: moveToCollectionId,
        },
        blobId: blob.id,
      }).unwrap();
      setMoveDialogOpen(false);
      onSuccess();
      setSelectedCollectionId(moveToCollectionId);
    } catch (e) {
      setError('Failed to move file');
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await destroyBlob({
        matterId: collectionTree.matter_id,
        blobId: blob.id,
      }).unwrap();
      handleClose();
      onSuccess();
    } catch (e) {
      setError('Failed to delete file');
    }
    setDeleteDialogOpen(false);
  };

  const renderCollectionOptions = (node: CollectionNode, level = 0) => {
    const options = [];
    const prefix = '\u00A0'.repeat(level * 2);

    options.push(
      <MenuItem key={node.id} value={node.id}>
        {prefix + (node.name === 'root' ? 'All Files' : node.name)}
      </MenuItem>,
    );

    if (node.children) {
      node.children.forEach((child) => {
        options.push(...renderCollectionOptions(child, level + 1));
      });
    }

    return options;
  };

  return (
    <>
      <IconButton edge="end" onClick={handleClick} disableRipple>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            setRenameDialogOpen(true);
          }}
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setMoveDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <DriveFileMove fontSize="small" />
          </ListItemIcon>
          <ListItemText>Move</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setDeleteDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {/* Rename Dialog */}
      <Dialog
        open={renameDialogOpen}
        onClose={() => setRenameDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Rename File</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="File Name"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRename} variant="contained">
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Move Dialog */}
      <Dialog
        open={moveDialogOpen}
        onClose={() => setMoveDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Move File</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>Destination Folder</InputLabel>
            <Select
              value={moveToCollectionId}
              onChange={(e) => setMoveToCollectionId(e.target.value)}
              label="Destination Folder"
            >
              {renderCollectionOptions(collectionTree)}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleMove} variant="contained">
            Move
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        itemCount={1}
        itemName={blob.name}
      />
    </>
  );
};
