import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowLeftIcon } from 'lucide-react';
import useMatterSelection from '../../hooks/useMatterSelection';
import { Documents } from '../matter-details/documents/Documents';
import { Matter } from '../matter/Matter';
import MatterTitle from '../MatterTitle';
import WelcomeScreen from './WelcomeScreen';
import MatterNotes from '../matter-notes/MatterNotes';
import Tasks from '../tasks/tasks';

const Cms: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();

  const mainContentRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = React.useState('home');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // Scroll to top when selectedMatterId changes
  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedMatterData?.id]);

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack direction="row" sx={{ flex: 1, overflow: 'hidden' }}>
        <Box sx={{ flexShrink: 1, maxWidth: '280px' }}>{/* <Sidebar /> */}</Box>
        {selectedMatterData ? (
          <Stack
            direction="column"
            sx={{
              flex: 1,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <NavLink to="/home" className="w-24 text-header mt-4 mx-8">
              <ArrowLeftIcon />
            </NavLink>
            <Box sx={{ px: 6, pt: 6 }}>
              <MatterTitle />
            </Box>

            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  px: 6,
                  backgroundColor: 'background.paper',
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Home"
                    value="home"
                    disableRipple
                    sx={{
                      fontFamily: 'area-normal, sans-serif',
                      fontWeight: '700',
                      letterSpacing: '0px',
                      textTransform: 'none',
                      color: 'info.light',
                      '&.Mui-selected': {
                        color: 'black',
                        borderColor: 'black',
                      },
                    }}
                  />
                  <Tab
                    label="Documents"
                    value="documents"
                    disableRipple
                    sx={{
                      fontFamily: 'area-normal, sans-serif',
                      fontWeight: '700',
                      letterSpacing: '0px',
                      textTransform: 'none',
                      color: 'info.light',
                      '&.Mui-selected': {
                        color: 'black',
                        borderColor: 'black',
                      },
                    }}
                  />
                  <Tab
                    label="Matter Details"
                    value="matter-details"
                    disableRipple
                    sx={{
                      fontFamily: 'area-normal, sans-serif',
                      fontWeight: '700',
                      letterSpacing: '0px',
                      textTransform: 'none',
                      color: 'info.light',
                    }}
                  />
                </TabList>
              </Box>

              <Box
                ref={mainContentRef}
                sx={{
                  flex: 1,
                  overflow: 'auto',
                  px: 6,
                  pb: 6,
                }}
              >
                <TabPanel value="home" sx={{ p: 0, pt: 2 }}>
                  {selectedMatterData && <Matter matter={selectedMatterData} />}
                </TabPanel>
                <TabPanel value="documents" sx={{ p: 0, pt: 2 }}>
                  <Documents />
                </TabPanel>
                <TabPanel value="matter-details" sx={{ p: 0, pt: 2 }}>
                  {selectedMatterData?.id && (
                    <MatterNotes matter={selectedMatterData} />
                  )}
                </TabPanel>
              </Box>
            </TabContext>
          </Stack>
        ) : (
          <WelcomeScreen />
        )}
      </Stack>
    </Box>
  );
};

export default Cms;
