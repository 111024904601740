import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useClearMatterNotesMutation } from '../../../services/api/matterNotesExtractionService';

interface ClearNotesButtonProps {
  matterId: string;
  onExtractionComplete?: () => void;
}

const ClearNotesButton = ({
  matterId,
  onExtractionComplete,
}: ClearNotesButtonProps): JSX.Element => {
  const [clearNotes, { isLoading }] = useClearMatterNotesMutation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClear = async () => {
    try {
      await clearNotes({ matterId });
      onExtractionComplete?.();
      handleClose();
    } catch (error) {
      console.error('Failed to clear notes:', error);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={handleClickOpen}
        startIcon={<DeleteIcon />}
        size="small"
      >
        Clear
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="clear-dialog-title"
        aria-describedby="clear-dialog-description"
      >
        <DialogTitle id="clear-dialog-title">Clear All Notes</DialogTitle>
        <DialogContent>
          <DialogContentText id="clear-dialog-description">
            Are you sure you want to clear all notes? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleClear}
            color="error"
            variant="contained"
            disabled={isLoading}
            startIcon={<DeleteIcon />}
            autoFocus
          >
            {isLoading ? 'Clearing...' : 'Clear'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClearNotesButton;
