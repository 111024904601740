import { Box } from '@mui/material';
import ExtractNotesButton from '../components/ExtractNotesButton';
import ClearNotesButton from '../components/ClearNotesButton';

interface MatterNotesExtractionButtonsProps {
  matterId: string;
  onExtractionComplete?: () => void;
}

const MatterNotesExtractionButtons = ({
  matterId,
  onExtractionComplete,
}: MatterNotesExtractionButtonsProps): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mb: 3 }}>
      <ClearNotesButton
        matterId={matterId}
        onExtractionComplete={onExtractionComplete}
      />
      <ExtractNotesButton
        matterId={matterId}
        onExtractionComplete={onExtractionComplete}
      />
    </Box>
  );
};

export default MatterNotesExtractionButtons;
