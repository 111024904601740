import { createApi } from '@reduxjs/toolkit/query/react';
import { Firm } from '../types/client-intake-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const firmsApiClient = createApi({
  reducerPath: 'firmsApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Firms'] as const,
  endpoints: (builder) => ({
    getFirms: builder.query<Firm[], void>({
      query: () => `api/firms/`,
      providesTags: ['Firms'],
    }),
  }),
});

export const { useGetFirmsQuery } = firmsApiClient;
