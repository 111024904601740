import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  IconButton,
  Skeleton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  NoteType,
  NoteTypeToModel,
  NoteUpdateRequest,
  isOneToOneNoteType,
} from '../../../services/types/matter-notes.types';
import {
  useGetOneToOneNoteQuery,
  useGetNotesQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} from '../../../services/api/matterNotesService';
import { useNotification } from '../../contexts/NotificationContext';
import theme from '../../../theme/theme';

interface NoteSectionProps<T extends NoteType> {
  matterId: string;
  noteType: T;
  title: string;
  FormFields: React.ComponentType<{
    initialData?: NoteTypeToModel[T];
    onSubmit: (data: NoteUpdateRequest<NoteTypeToModel[T]>) => void;
    isSubmitting: boolean;
    readOnly?: boolean;
    onDelete?: () => void;
  }>;
}

// Add loading skeleton component
const NoteLoadingSkeleton = () => (
  <ListItem
    sx={{
      p: 3,
      mb: 2,
      border: '1px solid',
      borderColor: 'border.main',
      borderRadius: 1,
      bgcolor: 'background.paper',
      display: 'block',
      width: '100%',
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Skeleton variant="text" width="60%" height={24} />
      <Skeleton variant="text" width="40%" height={24} />
      <Skeleton variant="text" width="80%" height={24} />
    </Box>
  </ListItem>
);

const ErrorState = ({ message }: { message: string }) => (
  <Box
    sx={{
      p: 3,
      mb: 2,
      border: '1px solid',
      borderColor: 'error.main',
      borderRadius: 1,
      bgcolor: 'error.lighter',
      color: 'error.main',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography>{message}</Typography>
  </Box>
);

const NoteSection = <T extends NoteType>({
  matterId,
  noteType,
  title,
  FormFields,
}: NoteSectionProps<T>) => {
  const [isAdding, setIsAdding] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<string | null>(null);
  const notify = useNotification();

  const isOneToOne = isOneToOneNoteType(noteType);

  // Queries based on note type
  const oneToOneResult = useGetOneToOneNoteQuery(
    { matterId, noteType },
    { skip: !isOneToOne },
  );
  const manyNotesResult = useGetNotesQuery(
    { matterId, noteType },
    { skip: isOneToOne },
  );

  const [createNote, { isLoading: isCreating }] = useCreateNoteMutation();
  const [updateNote, { isLoading: isUpdating }] = useUpdateNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();

  // Get typed data based on note type
  const oneToOneNote = oneToOneResult.data as NoteTypeToModel[T] | undefined;
  const manyNotes = manyNotesResult.data as NoteTypeToModel[T][] | undefined;

  // Sort notes by creation date, newest first
  const sortedNotes = React.useMemo(
    () =>
      [...(manyNotes || [])].sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      ),
    [manyNotes],
  );

  // Handle loading and error states
  const isLoading = isOneToOne
    ? oneToOneResult.isLoading
    : manyNotesResult.isLoading;
  const apiError = oneToOneResult.error || manyNotesResult.error;

  if (apiError) {
    return (
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2">{title}</Typography>
        </Box>
        <ErrorState message="Failed to load notes. Please try again later." />
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>
          {[1, 2, 3].map((i) => (
            <NoteLoadingSkeleton key={i} />
          ))}
        </Box>
      </Box>
    );
  }

  const handleSubmit = async (
    formData: NoteUpdateRequest<NoteTypeToModel[T]>,
    noteId?: string,
  ) => {
    try {
      if (noteId) {
        await updateNote({
          matterId,
          noteType,
          noteId,
          note: formData,
        }).unwrap();
        notify('Note updated successfully', 'success', 3000);
      } else {
        await createNote({
          matterId,
          noteType,
          note: formData,
        }).unwrap();
        notify('Note created successfully', 'success', 3000);
        setIsAdding(false);
      }
    } catch (error) {
      notify('Failed to save note', 'error', 3000);
      console.error('Failed to save note:', error);
    }
  };

  const handleDeleteClick = (noteId: string) => {
    setNoteToDelete(noteId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!noteToDelete) return;

    try {
      await deleteNote({ matterId, noteType, noteId: noteToDelete }).unwrap();
      notify('Note deleted successfully', 'success', 3000);
    } catch (error) {
      notify('Failed to delete note', 'error', 3000);
      console.error('Failed to delete note:', error);
    } finally {
      setDeleteDialogOpen(false);
      setNoteToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setNoteToDelete(null);
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2">{title}</Typography>
          {!isOneToOne && (
            <Button
              variant="text"
              onClick={() => setIsAdding(!isAdding)}
              startIcon={!isAdding && <AddIcon />}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {isAdding ? 'Cancel' : 'Add'}
            </Button>
          )}
        </Box>

        <Box
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: `1px solid ${theme.palette.border.main}`,
            position: 'relative',
          }}
        >
          {(isCreating || isUpdating) && (
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 2,
              }}
            >
              <CircularProgress size={20} />
            </Box>
          )}
          {isOneToOne ? (
            // One-to-one note form
            <FormFields
              initialData={oneToOneNote}
              onSubmit={(data) => handleSubmit(data, oneToOneNote?.id)}
              isSubmitting={isCreating || isUpdating}
            />
          ) : (
            // One-to-many notes list
            <List sx={{ p: 0, width: '100%' }}>
              {isAdding && (
                <ListItem
                  sx={{
                    mb: 2,
                    display: 'block',
                    width: '100%',
                  }}
                  disablePadding
                >
                  <FormFields
                    onSubmit={handleSubmit}
                    isSubmitting={isCreating}
                  />
                </ListItem>
              )}

              {sortedNotes.map((note) => (
                <ListItem
                  key={note.id}
                  sx={{
                    mb: 2,
                    display: 'block',
                    width: '100%',
                    position: 'relative',
                    '&:hover': {
                      '& .delete-button': {
                        opacity: 1,
                        visibility: 'visible',
                      },
                    },
                  }}
                  disablePadding
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 12,
                      right: 12,
                      zIndex: 2,
                    }}
                  >
                    <IconButton
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(note.id);
                      }}
                      size="small"
                      sx={{
                        opacity: 0,
                        visibility: 'hidden',
                        transition: 'all 0.2s ease-in-out',
                        bgcolor: 'error.lighter',
                        color: 'error.main',
                        '&:hover': {
                          bgcolor: 'error.light',
                          color: 'error.main',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ position: 'relative', zIndex: 1 }}>
                    <FormFields
                      initialData={note}
                      onSubmit={(data) => handleSubmit(data, note.id)}
                      isSubmitting={isUpdating}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete Note</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this note? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoteSection;
