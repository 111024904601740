import Tasks from '@/components/tasks/tasks';
import { Header } from '@/layout/Header';

export const TasksPage = () => {
  return (
    <div className="w-full">
      <Header />
      <Tasks />
    </div>
  );
};
