import { TextField, Box } from '@mui/material';
import {
  DamageNote,
  NOTE_TYPES,
} from '../../../services/types/matter-notes.types';
import NoteSection from '../components/NoteSection';
import BaseNoteForm, { useFormContext } from '../components/BaseNoteForm';

const DamageFormFields = () => {
  const { handleChange, formData } = useFormContext();

  const inputSx = {
    fontSize: '0.875rem',
    color: 'info.light',
  };

  const labelSx = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: 'text.secondary',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <TextField
        size="small"
        label="AMOUNT"
        name="amount"
        type="number"
        value={formData.amount || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
    </Box>
  );
};

const DamageNoteForm: React.FC<{
  initialData?: DamageNote;
  onSubmit: (data: Partial<DamageNote>) => void;
  isSubmitting: boolean;
}> = ({ initialData, onSubmit, isSubmitting }) => {
  return (
    <BaseNoteForm
      initialData={initialData}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      expandedFields={['amount']}
      prompt="Notable damages related to the matter"
    >
      <DamageFormFields />
    </BaseNoteForm>
  );
};

const DamageNoteSection = ({ matterId }: { matterId: string }) => {
  return (
    <NoteSection
      matterId={matterId}
      noteType={NOTE_TYPES.DAMAGE}
      title="Damages"
      FormFields={DamageNoteForm}
    />
  );
};

export default DamageNoteSection;
