import { Matters } from '../matters/Matters';
import { Header } from '@/layout/Header';

export const MattersPage = () => {
  return (
    <div className="w-full">
      <Header />
      <Matters />
    </div>
  );
};
