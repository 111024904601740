import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { DetailedMatter } from '../../../services/types/client-intake-types';
import ClientScreenTile from '../../base/ClientScreenTile';
import { useKeyConsiderations } from '../../../hooks/useKeyConsiderationsSelection';
import { FlagAndCategory } from '../../key_considerations/key_consideration/KeyConsiderationHeader';

interface ReadOnlyKeyConsiderationProps {
  matter: DetailedMatter;
}

const ReadOnlyKeyConsideration: React.FC<ReadOnlyKeyConsiderationProps> = ({
  matter,
}) => {
  const { mandatoryConsiderations, nonMandatoryConsiderations } =
    useKeyConsiderations(matter.key_considerations || []);

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 3,
          }}
        >
          <Typography variant="h2">Key Considerations</Typography>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            {mandatoryConsiderations.map((consideration) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={consideration.id}>
                <FlagAndCategory
                  consideration={consideration}
                  onChange={() => {}}
                />
                <Typography variant="body2" color="info.light">
                  {consideration.content}
                </Typography>
              </Grid>
            ))}
            {nonMandatoryConsiderations.map((consideration) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={consideration.id}>
                <FlagAndCategory
                  consideration={consideration}
                  onChange={() => {}}
                />
                <Typography variant="body2" color="info.light">
                  {consideration.content}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default ReadOnlyKeyConsideration;
