import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';
import {
  CreateTaskPayload,
  CreateTaskUpdatePayload,
  Task,
  TaskSearchResults,
  TaskUpdate,
  UpdateTaskPayload,
} from '../types/client-intake-types';

export const tasksApiClient = createApi({
  reducerPath: 'tasksApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Tasks', 'TaskUpdates'],
  endpoints: (builder) => ({
    // Task List endpoint
    getTasks: builder.query<
      TaskSearchResults,
      {
        search?: string;
        limit?: number;
        status?: string;
        matter?: string;
        matter__firm?: string;
        ordering?: string;
      }
    >({
      query: (params) => ({
        url: 'api/tasks/',
        params,
      }),
      providesTags: ['Tasks'],
    }),

    // Task Detail endpoints
    getTasksForMatter: builder.query<
      Task,
      { matterId: string; taskId: string }
    >({
      query: ({ matterId, taskId }) =>
        `api/matters/${matterId}/tasks/${taskId}/`,
      providesTags: (result, error, { taskId }) => [
        { type: 'Tasks', id: taskId },
      ],
    }),

    createTask: builder.mutation<
      Task,
      { matterId: string; task: CreateTaskPayload }
    >({
      query: ({ matterId, task }) => ({
        url: `api/matters/${matterId}/tasks/`,
        method: 'POST',
        body: task,
      }),
      invalidatesTags: ['Tasks'],
    }),

    updateTask: builder.mutation<
      Task,
      { matterId: string; taskId: string; task: UpdateTaskPayload }
    >({
      query: ({ matterId, taskId, task }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/`,
        method: 'PATCH',
        body: task,
      }),
      invalidatesTags: (result, error, { taskId }) => [
        'Tasks',
        { type: 'Tasks', id: taskId },
      ],
    }),

    deleteTask: builder.mutation<void, { matterId: string; taskId: string }>({
      query: ({ matterId, taskId }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),

    // Task Update endpoints
    getTaskUpdates: builder.query<
      TaskUpdate[],
      { matterId: string; taskId: string }
    >({
      query: ({ matterId, taskId }) =>
        `api/matters/${matterId}/tasks/${taskId}/updates/`,
      providesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    createTaskUpdate: builder.mutation<
      TaskUpdate,
      { matterId: string; taskId: string; update: CreateTaskUpdatePayload }
    >({
      query: ({ matterId, taskId, update }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/updates/`,
        method: 'POST',
        body: update,
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    updateTaskUpdate: builder.mutation<
      TaskUpdate,
      {
        matterId: string;
        taskId: string;
        updateId: string;
        update: CreateTaskUpdatePayload;
      }
    >({
      query: ({ matterId, taskId, updateId, update }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/updates/${updateId}/`,
        method: 'PATCH',
        body: update,
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    deleteTaskUpdate: builder.mutation<
      void,
      { matterId: string; taskId: string; updateId: string }
    >({
      query: ({ matterId, taskId, updateId }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/updates/${updateId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTasksForMatterQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useGetTaskUpdatesQuery,
  useCreateTaskUpdateMutation,
  useUpdateTaskUpdateMutation,
  useDeleteTaskUpdateMutation,
} = tasksApiClient;
