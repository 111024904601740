import { ChevronDownIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { TaskStatuses } from '@/services/types/client-intake-types';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';

const getStatusStyles = (status: string) => {
  switch (status.toLowerCase()) {
    case 'blocked':
      return 'bg-red-100 text-red-800';
    case 'in progress':
      return 'bg-blue-100 text-blue-700';
    case 'completed':
      return 'bg-green-100 text-green-700';
    default:
      return 'bg-gray-100 text-gray-700';
  }
};

export const TaskStatusBadge = ({
  status,
  onStatusChange,
}: {
  status: string;
  onStatusChange: (status: string) => void;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
        <span
          className={`flex flex-row justify-between items-center gap-1 px-2 py-2 rounded text-xs font-bold cursor-pointer ${getStatusStyles(
            status,
          )}`}
        >
          {status}
          <ChevronDownIcon size={12} />
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="bg-white rounded-sm border-gray-300"
        onClick={(e) => e.stopPropagation()}
      >
        {TaskStatuses.map((statusOption) => (
          <DropdownMenuItem
            key={statusOption}
            onClick={() => onStatusChange(statusOption)}
            className={cn(
              'cursor-pointer mb-1 font-semibold text-sm',
              getStatusStyles(statusOption),
            )}
          >
            {statusOption}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
