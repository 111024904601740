import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNotification } from '../components/contexts/NotificationContext';

interface UseReadOnlyDocumentPreviewProps {
  open: boolean;
  documentId: string;
}

const MAX_RETRIES = 3;
const INITIAL_DELAY = 3000; // 3 seconds

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const useReadOnlyLetterPreview = ({
  open,
  documentId,
}: UseReadOnlyDocumentPreviewProps) => {
  const { token } = useParams();
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    const fetchWithRetry = async (retryCount = 0): Promise<Blob | null> => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/shared-matter/${token}/documents/${documentId}/preview/`,
          {
            credentials: 'include',
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }

        return await response.blob();
      } catch (error) {
        if (retryCount < MAX_RETRIES) {
          // Exponential backoff: delay = initialDelay * 2^retryCount
          const delay = INITIAL_DELAY * 2 ** retryCount;
          await sleep(delay);
          return fetchWithRetry(retryCount + 1);
        }
        throw error;
      }
    };

    const fetchPDF = async () => {
      if (!open) {
        setPdfBlob(null);
        return;
      }

      setIsLoading(true);
      try {
        const blob = await fetchWithRetry();
        setPdfBlob(blob);
      } catch (error) {
        notification(
          'Failed to load document preview after multiple attempts',
          'error',
          5000,
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchPDF();

    // Cleanup function to clear the blob when dialog closes
    return () => {
      setPdfBlob(null);
    };
  }, [open, token, documentId, notification]);

  return { pdfBlob, isLoading };
};
