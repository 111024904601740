import { Box, Stack } from '@mui/material';
import { DetailedMatter } from '../../services/types/client-intake-types';
import IntakeCallSection from '../intake-form/IntakeCallSection';
import KeyConsiderations from '../key_considerations/KeyConsiderations';
import { Letters } from '../letters/Letters';
import Summaries from '../Summaries';
// import { MatterTasks } from './MatterTasks';
// import { useGetTasksQuery } from '@/services/api';

export const Matter = ({ matter }: { matter: DetailedMatter }) => {
  const hasSummaries = matter.summaries && matter.summaries.length > 0;
  const hasKeyConsiderations =
    matter.key_considerations && matter.key_considerations.length > 0;

  // TODO: Add tasks back in once they are synced with Airtable
  // const { data: tasks } = useGetTasksQuery({
  //   matter: matter.id,
  //   ordering: '-due_date',
  // });

  // const hasTasks = tasks && tasks.results.length > 0;

  return (
    <Stack direction="column" spacing={6}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* {hasTasks && <MatterTasks tasks={tasks.results} />} */}
        {hasSummaries && <Summaries />}
        {hasKeyConsiderations && <KeyConsiderations />}
      </Box>
      <IntakeCallSection />
      <Letters matter={matter} />
    </Stack>
  );
};
