import { TextField, Box } from '@mui/material';
import { IncidentNote } from '../../../services/types/matter-notes.types';
import NoteSection from '../components/NoteSection';
import BaseNoteForm, { useFormContext } from '../components/BaseNoteForm';

// Move form fields to a separate component that uses context
const IncidentFormFields = () => {
  const { handleChange, formData } = useFormContext();

  const inputSx = {
    fontSize: '0.875rem', // Smaller text size
    color: 'info.light', // Match BaseNoteForm text color
  };

  const labelSx = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: 'text.secondary',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <TextField
        size="small"
        label="DATE OF LOSS"
        name="date_of_loss"
        type="date"
        value={formData.date_of_loss || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
          sx: labelSx,
        }}
        inputProps={{
          sx: inputSx,
        }}
      />
      <TextField
        size="small"
        label="LOCATION"
        name="location"
        value={formData.location || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        multiline
        minRows={1}
        maxRows={4}
        InputLabelProps={{
          shrink: true,
          sx: labelSx,
        }}
        inputProps={{
          sx: inputSx,
        }}
      />
      <TextField
        size="small"
        label="WEATHER CONDITIONS"
        name="weather_conditions"
        value={formData.weather_conditions || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        multiline
        minRows={1}
        maxRows={4}
        InputLabelProps={{
          shrink: true,
          sx: labelSx,
        }}
        inputProps={{
          sx: inputSx,
        }}
      />
      <TextField
        size="small"
        label="INCIDENT TYPE"
        name="incident_type"
        value={formData.incident_type || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        multiline
        minRows={1}
        maxRows={4}
        InputLabelProps={{
          shrink: true,
          sx: labelSx,
        }}
        inputProps={{
          sx: inputSx,
        }}
      />
      <TextField
        size="small"
        label="INJURIES DESCRIBED"
        name="injuries_described"
        multiline
        minRows={2}
        maxRows={6}
        value={formData.injuries_described || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
          sx: labelSx,
        }}
        inputProps={{
          sx: inputSx,
        }}
      />
    </Box>
  );
};

const IncidentNoteForm: React.FC<{
  initialData?: IncidentNote;
  onSubmit: (data: Partial<IncidentNote>) => void;
  isSubmitting: boolean;
}> = ({ initialData, onSubmit, isSubmitting }) => {
  return (
    <BaseNoteForm
      // title="Incident Information"
      initialData={initialData}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      expandedFields={[
        'date_of_loss',
        'time_of_loss',
        'location',
        'weather_conditions',
        'injuries_described',
        'incident_type',
      ]}
      prompt="A narrative description of the accident"
    >
      <IncidentFormFields />
    </BaseNoteForm>
  );
};

const IncidentNoteSection: React.FC<{ matterId: string }> = ({ matterId }) => {
  return (
    <NoteSection
      matterId={matterId}
      noteType="incident"
      title="Accident Details"
      FormFields={IncidentNoteForm}
    />
  );
};

export default IncidentNoteSection;
