import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';

import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import useMatterSelection from '../hooks/useMatterSelection';
import { usePatchMatterMutation } from '../services/api';
import {
  DetailedMatter,
  MatterStatus,
  MatterType,
  MatterUpdate,
} from '../services/types/client-intake-types';
import { ShareDialog } from './share/ShareDialog';

const EditableField = ({
  value,
  isEditing,
  onStartEdit,
  onChange,
  type = 'text',
  options = [],
}: {
  value: string;
  isEditing: boolean;
  onStartEdit: () => void;
  onChange: (value: any) => void;
  type?: 'text' | 'select' | 'date';
  options?: string[];
}) => {
  if (isEditing) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        {type === 'select' ? (
          <Select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            variant="standard"
            size="small"
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            variant="standard"
            size="small"
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onClick={onStartEdit}
      sx={{
        cursor: 'pointer',
        '&:hover': { opacity: 0.8 },
      }}
    >
      <Typography variant="h4" color="info.light" noWrap>
        {value}
      </Typography>
    </Stack>
  );
};

const MatterTitleLeftSection = ({
  matterData,
}: {
  matterData: DetailedMatter | undefined;
}) => {
  const [patchMatterMutation] = usePatchMatterMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [tempName, setTempName] = useState(matterData?.name || '');
  const [tempStatus, setTempStatus] = useState<MatterStatus>(
    (matterData?.status as MatterStatus) || MatterStatus.INTAKE,
  );
  const [tempType, setTempType] = useState<MatterType>(
    matterData?.matter_type || MatterType.UNKNOWN,
  );
  const [tempDateOfLoss, setTempDateOfLoss] = useState(
    matterData?.date_of_loss || '',
  );

  useEffect(() => {
    setTempName(matterData?.name || '');
    setTempStatus((matterData?.status as MatterStatus) || MatterStatus.INTAKE);
    setTempType(matterData?.matter_type || MatterType.UNKNOWN);
    setTempDateOfLoss(matterData?.date_of_loss || '');
  }, [matterData]);

  const handleSave = async () => {
    if (!matterData?.id) return;

    const patch: MatterUpdate & { date_of_loss?: string } = {
      name: tempName,
      status: tempStatus,
      matter_type: tempType,
    };

    if (tempDateOfLoss) {
      patch.date_of_loss = tempDateOfLoss;
    }

    try {
      await patchMatterMutation({
        matter_id: matterData.id,
        patch,
      });
      setIsEditing(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleCancel = () => {
    setTempName(matterData?.name || '');
    setTempStatus((matterData?.status as MatterStatus) || MatterStatus.INTAKE);
    setTempType(matterData?.matter_type || MatterType.UNKNOWN);
    setTempDateOfLoss(matterData?.date_of_loss || '');
    setIsEditing(false);
  };

  return (
    <Stack direction="column" spacing={0}>
      {matterData?.id && (
        <Stack direction="row" alignItems="center" spacing={1}>
          {isEditing ? (
            <TextField
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              variant="standard"
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: 'h1.fontSize',
                  fontWeight: 'h1.fontWeight',
                },
                minWidth: 400,
                width: 800,
              }}
              autoFocus
            />
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={() => setIsEditing(true)}
              sx={{
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 },
                minWidth: 400,
                width: 800,
              }}
            >
              <Typography variant="h1" noWrap>
                {tempName}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4" color="info.light" noWrap>
          {matterData?.firm.name}
        </Typography>
        <Divider orientation="vertical" flexItem />

        <EditableField
          value={tempType}
          isEditing={isEditing}
          onStartEdit={() => setIsEditing(true)}
          onChange={(value: string) => setTempType(value as MatterType)}
          type="select"
          options={Object.values(MatterType)}
        />

        <Divider orientation="vertical" flexItem />

        <EditableField
          value={tempDateOfLoss}
          isEditing={isEditing}
          onStartEdit={() => setIsEditing(true)}
          onChange={setTempDateOfLoss}
          type="date"
        />

        <Divider orientation="vertical" flexItem />

        <EditableField
          value={tempStatus}
          isEditing={isEditing}
          onStartEdit={() => setIsEditing(true)}
          onChange={(value: string) => setTempStatus(value as MatterStatus)}
          type="select"
          options={Object.values(MatterStatus)}
        />

        <Stack direction="row" spacing={1}>
          {isEditing ? (
            <>
              <IconButton onClick={handleSave} color="primary" size="small">
                <CheckIcon />
              </IconButton>
              <IconButton onClick={handleCancel} color="default" size="small">
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <div />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const MatterTitle: React.FC = () => {
  const { selectedMatterData: matterData } = useMatterSelection();
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  return (
    <div>
      <Stack
        direction="row"
        gap="24px"
        marginBottom={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <MatterTitleLeftSection matterData={matterData} />

        <Stack direction="row" gap="24px">
          {matterData?.id && (
            <Button
              disableRipple
              variant="contained"
              color="primary"
              onClick={() => setIsShareDialogOpen(true)}
              sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 2 }}
            >
              <IosShareRoundedIcon />
              <Typography
                variant="body3"
                sx={{
                  textTransform: 'none',
                }}
              >
                Share Summary
              </Typography>
            </Button>
          )}
        </Stack>
        {matterData && (
          <ShareDialog
            open={isShareDialogOpen}
            onClose={() => setIsShareDialogOpen(false)}
            matterId={matterData.id}
          />
        )}
      </Stack>
    </div>
  );
};

export default MatterTitle;
