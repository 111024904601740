import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import {
  Dialog,
  DialogTitle,
  RightDialogContent,
} from '@/components/ui/dialog';
import { useCreateTaskMutation, useGetTasksQuery } from '@/services/api';
import { TaskStatus, TaskStatuses } from '@/services/types/client-intake-types';
import { TaskStatusBadge } from './TaskStatusBadge';
import { TaskDueDate } from './TaskDueDate';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface CreateTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const [createTask] = useCreateTaskMutation();
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [dueDate, setDueDate] = React.useState<Date>();
  const [status, setStatus] = React.useState<string>(TaskStatuses[0]);
  const [selectedMatterId, setSelectedMatterId] = React.useState<string>('');

  // Get matters for the dropdown
  const { data: tasks } = useGetTasksQuery({
    limit: 100, // Get more tasks to get a good list of matters
  });

  const uniqueMatters = React.useMemo(() => {
    if (!tasks?.results) return [];
    return Array.from(
      new Map(
        tasks.results.map((task) => [
          task.matter,
          { id: task.matter, name: task.matter_name },
        ]),
      ).values(),
    );
  }, [tasks?.results]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedMatterId || !name || !description) {
      return;
    }

    await createTask({
      matterId: selectedMatterId,
      task: {
        name,
        description,
        due_date: dueDate?.toISOString(),
        status: status as TaskStatus,
      },
    });

    setName('');
    setDescription('');
    setDueDate(undefined);
    setStatus(TaskStatuses[0]);
    setSelectedMatterId('');

    onClose();
    navigate(`/tasks`);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <RightDialogContent className="sm:max-w-[600px] bg-white flex flex-col gap-4  ">
        <DialogTitle className="text-xl font-bold">Create New Task</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4 pt-4">
          <div className="flex flex-col gap-1 space-y-2">
            <Label htmlFor="matter" className="font-bold">
              <span className="mb-2">Matter</span>
            </Label>
            <Select
              value={selectedMatterId}
              onValueChange={setSelectedMatterId}
              required
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a matter" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                {uniqueMatters.map((matter) => (
                  <SelectItem key={matter.id} value={matter.id}>
                    {matter.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-1 space-y-2">
            <Label htmlFor="name" className="font-bold">
              <span className="mb-2">Task Name</span>
            </Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter task name"
              required
              className="w-full"
            />
          </div>

          <div className="flex flex-col gap-1 space-y-2">
            <Label htmlFor="description" className="font-bold">
              <span className="mb-2">Description</span>
            </Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter task description"
              className="h-24"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1 space-y-2">
              <Label className="font-bold">Due Date</Label>
              <TaskDueDate
                date={dueDate?.toISOString()}
                onDateChange={setDueDate}
              />
            </div>
            <div className="flex flex-col gap-1 space-y-2">
              <Label className="font-bold">Status</Label>
              <div>
                <TaskStatusBadge
                  status={status}
                  onStatusChange={(newStatus) => {
                    if (newStatus) {
                      setStatus(newStatus);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              className="bg-white text-black font-bold"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!selectedMatterId}
              className="bg-primary text-white font-bold"
            >
              Create Task
            </Button>
          </div>
        </form>
      </RightDialogContent>
    </Dialog>
  );
};
