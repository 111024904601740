import {
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { InsuranceNote } from '../../../services/types/matter-notes.types';
import NoteSection from '../components/NoteSection';
import BaseNoteForm, { useFormContext } from '../components/BaseNoteForm';

const InsuranceFormFields = () => {
  const { handleChange, formData } = useFormContext();

  const inputSx = {
    fontSize: '0.875rem',
    color: 'info.light',
  };

  const labelSx = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: 'text.secondary',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <FormControl variant="standard" fullWidth size="small">
        <InputLabel sx={labelSx} shrink>
          HOLDER
        </InputLabel>
        <Select
          name="holder"
          value={formData.holder || ''}
          onChange={(e) =>
            handleChange({
              target: { name: 'holder', value: e.target.value },
            } as any)
          }
          sx={{
            ...inputSx,
            '& .MuiSelect-select': {
              paddingTop: '8px',
              paddingBottom: '4px',
            },
          }}
        >
          <MenuItem sx={{ fontSize: '0.875rem' }} value="FIRST_PARTY">
            First Party
          </MenuItem>
          <MenuItem sx={{ fontSize: '0.875rem' }} value="THIRD_PARTY">
            Third Party
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        size="small"
        label="CARRIER"
        name="carrier"
        value={formData.carrier || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="POLICY NUMBER"
        name="policy_number"
        value={formData.policy_number || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="POLICY LIMITATIONS"
        name="policy_limitations"
        value={formData.policy_limitations || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        multiline
        minRows={2}
        maxRows={4}
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="VEHICLE DESCRIPTION"
        name="vehicle_description"
        value={formData.vehicle_description || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="CLAIM NUMBER"
        name="claim_number"
        value={formData.claim_number || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="DATE FILED"
        name="date_filed"
        type="date"
        value={formData.date_filed || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="ADJUSTER NAME"
        name="adjuster_name"
        value={formData.adjuster_name || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="ADJUSTER PHONE"
        name="adjuster_phone"
        value={formData.adjuster_phone || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
      <TextField
        size="small"
        label="ADJUSTER EMAIL"
        name="adjuster_email"
        type="email"
        value={formData.adjuster_email || ''}
        onChange={handleChange}
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true, sx: labelSx }}
        inputProps={{ sx: inputSx }}
      />
    </Box>
  );
};

const InsuranceNoteForm: React.FC<{
  initialData?: InsuranceNote;
  onSubmit: (data: Partial<InsuranceNote>) => void;
  isSubmitting: boolean;
}> = ({ initialData, onSubmit, isSubmitting }) => {
  return (
    <BaseNoteForm
      initialData={initialData}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      expandedFields={[
        'holder',
        'carrier',
        'policy_number',
        'policy_limitations',
        'vehicle_description',
        'claim_number',
        'date_filed',
        'adjuster_name',
        'adjuster_phone',
        'adjuster_email',
      ]}
      prompt="An insurance policy involved in the matter"
    >
      <InsuranceFormFields />
    </BaseNoteForm>
  );
};

const InsuranceNoteSection: React.FC<{ matterId: string }> = ({ matterId }) => {
  return (
    <NoteSection
      matterId={matterId}
      noteType="insurance"
      title="Insurance"
      FormFields={InsuranceNoteForm}
    />
  );
};

export default InsuranceNoteSection;
