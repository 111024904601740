import { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {
  useExtractMatterNotesMutation,
  useGetExtractMatterNotesStatusQuery,
} from '../../../services/api/matterNotesExtractionService';

interface ExtractNotesButtonProps {
  matterId: string;
  onExtractionComplete?: () => void;
}

const ExtractNotesButton = ({
  matterId,
  onExtractionComplete,
}: ExtractNotesButtonProps): JSX.Element => {
  const [extractNotes, { isLoading: isExtracting }] =
    useExtractMatterNotesMutation();
  const [groupId, setGroupId] = useState<string | null>(null);

  const { data: statusData, isLoading: isCheckingStatus } =
    useGetExtractMatterNotesStatusQuery(
      { matterId, groupId: groupId || '' },
      { skip: !groupId, pollingInterval: 2000 },
    );

  useEffect(() => {
    if (statusData?.status === 'SUCCESS' && statusData.completed) {
      const allTasksSuccessful = statusData.results?.every(
        (task) => task.result.success,
      );

      if (allTasksSuccessful) {
        setGroupId(null);
        onExtractionComplete?.();
      } else {
        console.error('Some extraction tasks failed:', statusData.results);
        setGroupId(null);
      }
    } else if (statusData?.status === 'FAILURE') {
      console.error('Extraction failed:', statusData);
      setGroupId(null);
    }
  }, [statusData, onExtractionComplete]);

  const handleExtract = async () => {
    try {
      const response = await extractNotes({ matterId }).unwrap();
      setGroupId(response.group_id);
    } catch (error) {
      console.error('Failed to extract notes:', error);
    }
  };

  const isLoading = isExtracting || isCheckingStatus || !!groupId;

  return (
    <Button
      variant="contained"
      onClick={handleExtract}
      disabled={isLoading}
      startIcon={
        isLoading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          <AutoStoriesIcon />
        )
      }
      size="small"
    >
      {isLoading ? 'Extracting...' : 'Generate'}
    </Button>
  );
};

export default ExtractNotesButton;
