import {
  Autocomplete,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateMatterMutation } from '../../services/api';
import { useGetFirmsQuery } from '../../services/api/firmsService';
import { selectUser } from '../../state/selectors/root-selectors';
import { sanitizePhoneNumber } from '../../utils/validation';
import { SecondaryButton } from '../base/Buttons';
import { ControlledPhoneField } from '../base/forms/ControlledFields';
import { TextFormField } from '../base/forms/TextFormField';
import { Modal } from '../base/Modal';
import { useNotification } from '../contexts/NotificationContext';

interface CreateMatterFormState {
  name: string;
  phoneNumber: string;
  firm: string;
}

const CreateMatterForm: React.FC<{
  onSubmit: (data: CreateMatterFormState) => void;
}> = ({ onSubmit }) => {
  const { data: firms } = useGetFirmsQuery();
  const form = useForm<CreateMatterFormState>({
    mode: 'onChange',
  });

  return (
    <Stack gap={4}>
      <TextFormField
        {...form.register('name', { required: true })}
        label="Matter Name"
        variant="standard"
      />
      <ControlledPhoneField
        control={form.control}
        name="phoneNumber"
        label="Phone Number"
      />

      <Controller
        control={form.control}
        name="firm"
        render={({ field: { onChange, onBlur, value } }) => (
          <Autocomplete
            onChange={(event, newValue) => {
              onChange(newValue?.id);
            }}
            onBlur={onBlur}
            value={{
              label: firms?.find((firm) => firm.id === value)?.name,
              id: value,
            }}
            options={
              firms?.map((firm) => ({ label: firm.name, id: firm.id })) || []
            }
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                label="Firm"
                // https://github.com/mui/material-ui/issues/36605#issuecomment-2538475859
                slotProps={{
                  htmlInput: params?.inputProps,
                  input: params?.InputProps,
                }}
                {...params}
              />
            )}
          />
        )}
      />

      <SecondaryButton
        disabled={!form.formState.isValid}
        onClick={form.handleSubmit(onSubmit)}
      >
        Submit
      </SecondaryButton>
    </Stack>
  );
};

export const CreateMatterModal: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const [createMatter] = useCreateMatterMutation();
  const notify = useNotification();
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const handleSubmit = async (data: CreateMatterFormState) => {
    if (!user) {
      notify('User must be logged in to create a matter', 'error', 3000);
      return;
    }

    const result = await createMatter({
      firm: data.firm,
      creator: user.id,
      name: data.name,
      clients: [
        {
          phone_numbers: [
            { phone_number: sanitizePhoneNumber(data.phoneNumber) },
          ],
        },
      ],
    });

    if (result.data) {
      notify(
        `Matter ${result.data.matter.name} created successfully`,
        'success',
        3000,
      );
      navigate(`/matters/${result.data.matter.id}`);
      setIsOpen(false);
    } else {
      notify(`Failed to create matter ${data.name}`, 'error', 3000);
    }
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Add Matter</Typography>
        <Divider />
        <CreateMatterForm onSubmit={handleSubmit} />
      </Stack>
    </Modal>
  );
};
