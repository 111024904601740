import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Modal } from '../base/Modal';
import LoggedInUser from '../LoggedInUser';

interface ProfileSettingsModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const ProfileSettingsModal: React.FC<ProfileSettingsModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Account</Typography>
        <Divider />
        <LoggedInUser />
      </Stack>
    </Modal>
  );
};

export const ProfileSettings: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className="flex items-center gap-2 bg-transparent hover:bg-transparent"
      >
        <AccountCircleIcon sx={{ color: 'white' }} />
        <h3 className="text-primary-light hover:bg-transparent font-bold">
          Account
        </h3>
      </Button>
      <ProfileSettingsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
