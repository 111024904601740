import React from 'react';

import { cn } from '@/lib/utils';
import { Calendar } from '../ui/calendar';

import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

export const TaskDueDate = ({
  date,
  onDateChange,
}: {
  date?: string;
  onDateChange: (date: Date | undefined) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger onClick={(e) => e.stopPropagation()}>
        <div
          className={cn(
            'flex border border-gray-300 w-[150px] items-center gap-2 px-3 py-2 rounded hover:bg-gray-100 cursor-pointer text-center',
            !date && 'text-muted-foreground',
          )}
        >
          <div className="w-full text-center">
            {date ? new Date(date).toLocaleDateString() : 'Pick a date'}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0"
        align="start"
        onClick={(e) => e.stopPropagation()}
      >
        <Calendar
          className="bg-white cursor-pointer border-gray-300"
          mode="single"
          selected={date ? new Date(date) : undefined}
          onSelect={(selectedDate) => {
            onDateChange(selectedDate);
            setOpen(false);
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};
