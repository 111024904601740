import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';
import {
  ExtractMatterNotesResponse,
  GetExtractMatterNotesStatusResponse,
} from '../types/matter-notes-extraction.types';
import { NOTE_TYPES } from '../types/matter-notes.types';
import { matterNotesApi } from './matterNotesService';

export const matterNotesExtractionApi = createApi({
  reducerPath: 'matterNotesExtractionApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Notes'] as const,
  endpoints: (builder) => ({
    // Endpoint to clear all matter notes for a given matter.
    clearMatterNotes: builder.mutation<void, { matterId: string }>({
      query: ({ matterId }) => ({
        url: `api/matters/${matterId}/notes/clear/`,
        method: 'POST',
      }),
      async onQueryStarted({ matterId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // Invalidate all note types for this matter
          Object.values(NOTE_TYPES).forEach((noteType) => {
            dispatch(
              matterNotesApi.util.invalidateTags([
                { type: 'Notes', id: `${matterId}-${noteType}` },
              ]),
            );
          });
        } catch {
          // Error handling is done in the component
        }
      },
    }),

    // Endpoint to trigger extraction of matter notes.
    extractMatterNotes: builder.mutation<
      ExtractMatterNotesResponse,
      { matterId: string }
    >({
      query: ({ matterId }) => ({
        url: `api/matters/${matterId}/notes/extract/`,
        method: 'POST',
      }),
    }),

    // Endpoint to get the status of a matter notes extraction task group.
    getExtractMatterNotesStatus: builder.query<
      GetExtractMatterNotesStatusResponse,
      { matterId: string; groupId: string }
    >({
      query: ({ matterId, groupId }) => ({
        url: `api/matters/${matterId}/notes/extract/`,
        method: 'GET',
        params: { group_id: groupId },
      }),
      async onQueryStarted(
        { matterId, groupId },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === 'SUCCESS' && data.completed) {
            const allTasksSuccessful = data.results?.every(
              (task) => task.result.success,
            );
            if (allTasksSuccessful) {
              // Invalidate all note types for this matter
              Object.values(NOTE_TYPES).forEach((noteType) => {
                dispatch(
                  matterNotesApi.util.invalidateTags([
                    { type: 'Notes', id: `${matterId}-${noteType}` },
                  ]),
                );
              });
            }
          }
        } catch {
          // Error handling is done in the component
        }
      },
    }),
  }),
});

export const {
  useClearMatterNotesMutation,
  useExtractMatterNotesMutation,
  useGetExtractMatterNotesStatusQuery,
} = matterNotesExtractionApi;
