import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DetailedMatter } from '../../../services/types/client-intake-types';
import ClientScreenTile from '../../base/ClientScreenTile';
import AudioPlayer from '../../intake-form/AudioPlayer';
import theme from '../../../theme/theme';

interface ReadOnlyIntakeCallSectionProps {
  matter: DetailedMatter;
}

const ReadOnlyIntakeCallSection: React.FC<ReadOnlyIntakeCallSectionProps> = ({
  matter,
}) => {
  const [playingConversationId, setPlayingConversationId] = useState<
    string | null
  >(null);
  const conversations = matter?.conversations || [];

  const handlePlay = (conversationId: string) => {
    setPlayingConversationId(conversationId);
  };

  if (conversations.length === 0) {
    return null;
  }

  return (
    <ClientScreenTile>
      <Stack spacing={3}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box
            sx={{
              width: '100%',
              borderBottom: `1px solid ${theme.palette.border.main}`,
              paddingBottom: 3,
            }}
          >
            <Typography variant="h2">Calls</Typography>
          </Box>
        </Stack>
        {conversations.map((conversation) => (
          <Stack key={conversation.id} spacing={4}>
            <AudioPlayer
              conversation={conversation}
              onPlay={() => handlePlay(conversation.id)}
              playingConversationId={playingConversationId}
            />
          </Stack>
        ))}
      </Stack>
    </ClientScreenTile>
  );
};

export default ReadOnlyIntakeCallSection;
