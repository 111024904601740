import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import {
  useCreateSharedMatterLinkMutation,
  useDeleteSharedMatterLinkMutation,
  useGetSharedMatterLinksQuery,
} from '../../services/api/sharedMatterService';
import { useNotification } from '../contexts/NotificationContext';
import { isEmailValid } from '../../utils/validation';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginTop: theme.spacing(3),
}));

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.backgroundGray.light,
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  opacity: 0.5,
  ...theme.typography.h3,
}));

const StyledBodyRow = styled(TableRow)();

const StyledBodyCell = styled(TableCell)();

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
  marginBottom: '24px',
}));

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  matterId: string;
}

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmText: string;
  confirmColor?: 'primary' | 'error';
}

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  confirmColor = 'primary',
}: ConfirmationDialogProps) => (
  <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} color={confirmColor} variant="contained">
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export const ShareDialog = ({ open, onClose, matterId }: ShareDialogProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    type: 'resend' | 'revoke';
    email: string;
    token: string;
  }>({
    open: false,
    type: 'resend',
    email: '',
    token: '',
  });

  const { data: shareLinks } = useGetSharedMatterLinksQuery(matterId);
  const [createSharedMatterLink] = useCreateSharedMatterLinkMutation();
  const [deleteSharedMatterLink] = useDeleteSharedMatterLinkMutation();

  const notify = useNotification();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail && !isEmailValid(newEmail)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleShare = async () => {
    setEmail('');
    if (!email || !isEmailValid(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    try {
      const { error } = await createSharedMatterLink({
        matter_id: matterId,
        email,
      });
      if (error) {
        notify('Failed to create share link', 'error', 3000);
        return;
      }
      setEmail('');
      setEmailError('');
      notify('Share link created and sent', 'success', 3000);
    } catch (error) {
      notify('Failed to create share link', 'error', 3000);
    }
  };

  const handleRevoke = async (revokeEmail: string) => {
    try {
      const { error } = await deleteSharedMatterLink({
        matter_id: matterId,
        email: revokeEmail,
      });
      if (error) {
        notify('Failed to revoke share link', 'error', 3000);
        return;
      }
      notify('Share link revoked', 'success', 3000);
      setConfirmationDialog((prev) => ({ ...prev, open: false }));
    } catch (error) {
      notify('Failed to revoke share link', 'error', 3000);
    }
  };

  const handleResend = async (resendEmail: string) => {
    try {
      const { error } = await createSharedMatterLink({
        matter_id: matterId,
        email: resendEmail,
      });
      if (error) {
        notify('Failed to resend share link', 'error', 3000);
        return;
      }
      notify('Share link resent', 'success', 3000);
      setConfirmationDialog((prev) => ({ ...prev, open: false }));
    } catch (error) {
      notify('Failed to resend share link', 'error', 3000);
    }
  };

  const handleCopy = async (token: string) => {
    try {
      // Construct the share URL - this should match your frontend route for shared matters
      const shareUrl = `${window.location.origin}/shared-matter/${token}`;
      await navigator.clipboard.writeText(shareUrl);
      notify('Share link copied to clipboard', 'success', 3000);
    } catch (error) {
      notify('Failed to copy share link', 'error', 3000);
    }
  };

  const handleConfirmAction = () => {
    const { type, email: targetEmail } = confirmationDialog;
    if (type === 'revoke') {
      handleRevoke(targetEmail);
    } else {
      handleResend(targetEmail);
    }
  };

  const openConfirmationDialog = (
    type: 'resend' | 'revoke',
    targetEmail: string,
    token: string,
  ) => {
    setConfirmationDialog({
      open: true,
      type,
      email: targetEmail,
      token,
    });
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialog((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            padding: 4,
            borderRadius: 2,
          },
        }}
      >
        <StyledDialogTitle>
          <Typography variant="h2">Share Case Summary</Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: 'text.secondary',
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Box>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Share with a new email address:
              </Typography>
              <Stack direction="row" spacing={4}>
                <TextField
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter email address"
                  fullWidth
                  error={!!emailError}
                  helperText={emailError}
                />
                <Button
                  variant="contained"
                  onClick={handleShare}
                  disabled={!email || !!emailError}
                  sx={{
                    minWidth: 100,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    maxHeight: '36px',
                  }}
                >
                  <Typography variant="body2" sx={{ textTransform: 'none' }}>
                    Share
                  </Typography>
                  <SendIcon sx={{ width: 20, height: 20 }} />
                </Button>
              </Stack>
            </Box>

            {shareLinks && shareLinks.length > 0 && (
              <Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Active shares:
                </Typography>
                <StyledTableContainer>
                  <StyledTable>
                    <TableHead>
                      <StyledHeaderRow>
                        <StyledHeaderCell>Email</StyledHeaderCell>
                        <StyledHeaderCell>Expires</StyledHeaderCell>
                        <StyledHeaderCell>Last Accessed</StyledHeaderCell>
                        <StyledHeaderCell align="right">
                          Actions
                        </StyledHeaderCell>
                      </StyledHeaderRow>
                    </TableHead>
                    <TableBody>
                      {shareLinks.map((link) => (
                        <StyledBodyRow key={link.token}>
                          <StyledBodyCell>{link.email}</StyledBodyCell>
                          <StyledBodyCell>
                            {new Date() > new Date(link.expires_at) ? (
                              <Typography variant="body2" color="error">
                                Expired
                              </Typography>
                            ) : (
                              `${new Date(link.expires_at).toLocaleDateString(
                                'en-US',
                                {
                                  month: 'short',
                                  day: 'numeric',
                                },
                              )} 
                              (${Math.floor(
                                (new Date(link.expires_at).getTime() -
                                  new Date().getTime()) /
                                  (1000 * 60 * 60 * 24),
                              )} days)`
                            )}
                          </StyledBodyCell>
                          <StyledBodyCell>
                            {link.last_accessed_at
                              ? new Date(
                                  link.last_accessed_at,
                                ).toLocaleDateString()
                              : 'Never'}
                          </StyledBodyCell>
                          <StyledBodyCell align="right">
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-end"
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleCopy(link.token)}
                                title="Copy Link"
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openConfirmationDialog(
                                    'resend',
                                    link.email,
                                    link.token,
                                  )
                                }
                                title="Resend"
                              >
                                <SendIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openConfirmationDialog(
                                    'revoke',
                                    link.email,
                                    link.token,
                                  )
                                }
                                title="Revoke"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          </StyledBodyCell>
                        </StyledBodyRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </Box>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        open={confirmationDialog.open}
        onClose={closeConfirmationDialog}
        onConfirm={handleConfirmAction}
        title={
          confirmationDialog.type === 'revoke'
            ? 'Confirm Revoke Access'
            : 'Confirm Resend Link'
        }
        message={
          confirmationDialog.type === 'revoke'
            ? `Are you sure you want to revoke access for ${confirmationDialog.email}?`
            : `Are you sure you want to resend the link to ${confirmationDialog.email}?`
        }
        confirmText={confirmationDialog.type === 'revoke' ? 'Revoke' : 'Resend'}
        confirmColor={
          confirmationDialog.type === 'revoke' ? 'error' : 'primary'
        }
      />
    </>
  );
};
