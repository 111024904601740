import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  useCreateDocumentMutation,
  useGetDocumentTemplateFieldsQuery,
} from '../../services/api/lettersService';
import {
  DocumentTemplate,
  DocumentTemplateField,
  DocumentTemplateFields,
} from '../../services/types/letters-types';
import { useNotification } from '../contexts/NotificationContext';

const REQUIRED_CONTRACT_FIELDS = {
  CLIENT_NAME: {
    mapping: 'client_name',
    display_name: 'Client Name',
  },
  CLIENT_EMAIL: {
    mapping: 'client_email_address',
    display_name: 'Client Email',
  },
} as const;

const OPTIONAL_CONTRACT_FIELDS = {
  CLIENT_PHONE: {
    mapping: 'client_phone_number',
    display_name: 'Client Phone Number',
  },
} as const;

type RequiredField =
  (typeof REQUIRED_CONTRACT_FIELDS)[keyof typeof REQUIRED_CONTRACT_FIELDS]['mapping'];

type OptionalField =
  (typeof OPTIONAL_CONTRACT_FIELDS)[keyof typeof OPTIONAL_CONTRACT_FIELDS]['mapping'];

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 36,
  padding: 0,
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: 0,
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  padding: 0,
  '& .MuiButton-contained': {
    width: '100%',
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.24)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'primary.main',
      borderWidth: 1,
    },
  },
});

interface CreateLetterProps {
  open: boolean;
  onClose: () => void;
  matter_id: string;
  template: DocumentTemplate;
  firm_id: string;
}

interface LetterFormData {
  [key: string]: string;
}

const LetterForm = ({
  fields,
  onSubmit,
  requiresSignatures,
}: {
  fields: DocumentTemplateField[];
  onSubmit: (data: LetterFormData) => void;
  requiresSignatures: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LetterFormData>();

  const requiredFields: RequiredField[] = requiresSignatures
    ? [
        REQUIRED_CONTRACT_FIELDS.CLIENT_NAME.mapping,
        REQUIRED_CONTRACT_FIELDS.CLIENT_EMAIL.mapping,
      ]
    : [];

  const optionalFields: OptionalField[] = requiresSignatures
    ? [OPTIONAL_CONTRACT_FIELDS.CLIENT_PHONE.mapping]
    : [];

  // Ensure required fields are present and at the top
  const sortedFields = [
    // Required fields first
    ...requiredFields.map(
      (mapping) =>
        fields.find((f) => f.mapping === mapping) || {
          id: mapping,
          mapping,
          display_name:
            Object.values(REQUIRED_CONTRACT_FIELDS).find(
              (f) => f.mapping === mapping,
            )?.display_name || '',
          default_value: '',
          external_reference: mapping,
        },
    ),
    ...optionalFields.map(
      (mapping) =>
        fields.find((f) => f.mapping === mapping) || {
          id: mapping,
          mapping,
          display_name:
            Object.values(OPTIONAL_CONTRACT_FIELDS).find(
              (f) => f.mapping === mapping,
            )?.display_name || '',
          default_value: '',
          external_reference: mapping,
        },
    ),
    // Then all other fields that aren't required
    ...fields.filter(
      (field) =>
        !requiredFields.includes(field.mapping as RequiredField) &&
        !optionalFields.includes(field.mapping as OptionalField),
    ),
  ];

  return (
    <form id="letter-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        {sortedFields.map((field) => (
          <Grid item xs={6} key={field.id}>
            <Stack spacing={1}>
              <Typography
                variant="body3"
                sx={{
                  textTransform: 'uppercase',
                  lineHeight: 1.25,
                  color: (theme) => theme.typography.subtitle1.color,
                }}
              >
                {field.display_name}
              </Typography>
              <StyledTextField
                {...register(field.external_reference, {
                  required: requiredFields.includes(
                    field.mapping as RequiredField,
                  )
                    ? 'This field is required'
                    : false,
                })}
                defaultValue={field.default_value}
                fullWidth
                multiline
                size="small"
                error={!!errors[field.external_reference]}
                helperText={errors[field.external_reference]?.message}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </form>
  );
};

const LetterFormContent = ({
  isLoading,
  templateFields,
  onSubmit,
  requiresSignatures,
}: {
  isLoading: boolean;
  templateFields?: DocumentTemplateFields;
  onSubmit: (data: LetterFormData) => void;
  requiresSignatures: boolean;
}) => {
  if (isLoading) {
    return <Typography>Loading template fields...</Typography>;
  }

  if (!templateFields?.fields) {
    return <Typography>No fields found for this template.</Typography>;
  }

  return (
    <LetterForm
      fields={templateFields.fields}
      onSubmit={onSubmit}
      requiresSignatures={requiresSignatures}
    />
  );
};

export const CreateLetter = ({
  open,
  onClose,
  matter_id,
  template,
  firm_id,
}: CreateLetterProps) => {
  const { data: templateFields, isLoading: isLoadingFields } =
    useGetDocumentTemplateFieldsQuery({
      firmId: firm_id,
      templateId: template.id,
      matterId: matter_id,
    });

  const notification = useNotification();
  const [createDocument, { isLoading: isCreating }] =
    useCreateDocumentMutation();

  const handleSubmit = async (data: LetterFormData) => {
    try {
      await createDocument({
        matterId: matter_id,
        data: {
          document_template_id: template.id,
          merge_fields: data,
        },
      }).unwrap();
      onClose();
    } catch (error) {
      notification('Failed to create document', 'error', 5000);
    }
  };

  const title = template.requires_signatures
    ? 'Create Contract'
    : 'Create Letter';

  const actionText = template.requires_signatures
    ? 'Create Contract'
    : 'Create Letter';

  const isLoading = isLoadingFields || isCreating;

  return (
    <Dialog
      open={open}
      onClose={isLoading ? undefined : onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          minWidth: 720,
          padding: 6,
          borderRadius: 4,
        },
      }}
    >
      <Stack spacing={6}>
        <StyledDialogTitle>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="subtitle1">{template.name}</Typography>
        </StyledDialogTitle>
        <StyledDialogContent>
          <LetterFormContent
            isLoading={isLoadingFields}
            templateFields={templateFields}
            onSubmit={handleSubmit}
            requiresSignatures={template.requires_signatures}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="letter-form"
            disabled={isLoading}
            sx={{ height: 48 }}
          >
            <Typography variant="h3" sx={{ textTransform: 'none' }}>
              {isCreating ? 'Creating...' : actionText}
            </Typography>
          </Button>
        </StyledDialogActions>
      </Stack>
    </Dialog>
  );
};
