import { Button, Stack, Typography } from '@mui/material';
import { formatDistanceToNow, isPast } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DetailedMatter } from '../../services/types/client-intake-types';
import {
  useAcceptSharedMatterMutation,
  useDeclineSharedMatterMutation,
  useGetSharedMatterStatusQuery,
} from '../../services/api';

interface ReadOnlyMatterTitleProps {
  matter: DetailedMatter;
}

const ReadOnlyMatterTitle: React.FC<ReadOnlyMatterTitleProps> = ({
  matter,
}) => {
  const { token } = useParams();
  const { data: sharedMatterStatus } = useGetSharedMatterStatusQuery(token);
  const [acceptSharedMatter] = useAcceptSharedMatterMutation();
  const [declineSharedMatter] = useDeclineSharedMatterMutation();

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'accepted':
        return 'success.main';
      case 'declined':
        return 'error.main';
      default:
        return 'text.secondary';
    }
  };

  return (
    <div>
      <Stack
        direction="row"
        gap="24px"
        marginBottom={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h1">{matter.name}</Typography>
          <Typography variant="h4" color="info.light">
            {matter.type}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          {sharedMatterStatus && sharedMatterStatus.status === 'Pending' ? (
            <>
              <Button
                disableRipple
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: 'none',
                  backgroundColor: 'white',
                  boxShadow: 'none',
                  outline: '1px solid',
                  borderRadius: '2px',
                }}
                onClick={async () => {
                  await declineSharedMatter({
                    token: token!,
                  });
                }}
              >
                <Typography variant="body3">Drop Client</Typography>
              </Button>
              <Button
                disableRipple
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none',
                  borderRadius: '2px',
                }}
                onClick={async () => {
                  await acceptSharedMatter({
                    token: token!,
                  });
                }}
              >
                <Typography variant="body3">Approve</Typography>
              </Button>
            </>
          ) : (
            <Typography
              variant="body1"
              color={getStatusColor(sharedMatterStatus?.status || '')}
            >
              Status: {sharedMatterStatus?.status}
            </Typography>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default ReadOnlyMatterTitle;
