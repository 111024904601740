import { SxProps, Theme } from '@mui/material';

export const useFormFieldStyles = () => {
  const inputSx: SxProps<Theme> = {
    fontSize: '0.875rem', // Smaller text size
    color: 'info.light', // Match BaseNoteForm text color
  };

  const labelSx: SxProps<Theme> = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.08em',
    color: 'text.secondary',
  };

  return {
    inputSx,
    labelSx,
    defaultFieldProps: {
      size: 'small' as const,
      variant: 'standard' as const,
      fullWidth: true,
      multiline: true,
      minRows: 1,
      maxRows: 4,
      InputLabelProps: {
        shrink: true,
        sx: labelSx,
      },
      inputProps: {
        sx: inputSx,
      },
    },
  };
};
