import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseMatterNote } from '../../../services/types/matter-notes.types';

interface BaseNoteFormProps<T extends BaseMatterNote> {
  initialData?: T;
  onSubmit: (data: Partial<T>) => void;
  isSubmitting: boolean;
  title?: string;
  onDelete?: () => void;
  expandedFields?: (keyof T)[];
  children?: React.ReactNode;
  prompt?: string;
  minHeight?: string | number;
  textRows?: number;
}

export const FormContext = React.createContext<{
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: any;
  isDirty: boolean;
} | null>(null);

const BaseNoteForm = <T extends BaseMatterNote>({
  initialData,
  onSubmit,
  isSubmitting,
  title,
  onDelete,
  expandedFields = [],
  children,
  prompt,
  minHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  textRows,
}: BaseNoteFormProps<T>) => {
  const [formData, setFormData] = useState<Partial<T>>(initialData || {});
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      setIsDirty(true);
    },
    [],
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData as Partial<T>);
    setIsDirty(false);
  };

  // Show expanded section if any fields have values or if editing
  const shouldShowExpanded =
    expandedFields.some((field) => !!formData[field]) || isDirty;

  const contextValue = React.useMemo(
    () => ({ handleChange, formData, isDirty }),
    [handleChange, formData, isDirty],
  );

  // Add keyboard shortcut handler
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault();
        if (isDirty && !isSubmitting) {
          onSubmit(formData as Partial<T>);
          setIsDirty(false);
        }
      }
    },
    [formData, isDirty, isSubmitting, onSubmit],
  );

  // Add and remove event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Add ref for the TextField
  const descriptionRef = React.useRef<HTMLTextAreaElement>(null);

  // Add click handler for the left pane
  const handleLeftPaneClick = (e: React.MouseEvent) => {
    // Only focus if we're clicking the container and not already focused on the input
    if (e.target === e.currentTarget) {
      descriptionRef.current?.focus();
    }
  };

  return (
    <FormContext.Provider value={contextValue}>
      <Box component="form" onSubmit={handleSubmit}>
        {(title || (initialData && onDelete)) && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            {title && <Typography variant="subtitle1">{title}</Typography>}
            {initialData && onDelete && (
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: shouldShowExpanded ? '1fr 1fr' : '1fr',
            gap: 2,
            minHeight: minHeight ?? 'auto',
            p: 3,
            border: '1px solid',
            borderColor: 'border.main',
            borderRadius: 1,
            bgcolor: 'background.paper',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              bgcolor: 'background.default',
              borderColor: 'primary.main',
            },
          }}
        >
          {/* Left pane - Description */}
          <Box
            onClick={handleLeftPaneClick}
            sx={{
              backgroundColor: 'background.paper',
              cursor: 'text',
            }}
          >
            <TextField
              fullWidth
              name="description"
              multiline
              inputRef={descriptionRef}
              minRows={shouldShowExpanded ? 4 : 1}
              maxRows={20}
              value={formData.description || ''}
              onChange={handleChange}
              sx={{
                height: minHeight ?? 'fit-content',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                  backgroundColor: 'transparent',
                  padding: 0,
                },
                '& .MuiInputBase-input': {
                  padding: 0,
                  color: 'info.light',
                  fontSize: '0.875rem',
                  minHeight: '24px',
                },
              }}
              InputProps={{
                sx: {
                  height: 'auto',
                },
              }}
              placeholder={prompt}
              variant="outlined"
              slotProps={{
                formHelperText: {
                  sx: {
                    opacity: formData.description ? 0 : 0.7,
                    transition: 'opacity 0.2s ease',
                  },
                },
              }}
            />
          </Box>

          {/* Right pane - Form Fields */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              transition: 'all 0.3s ease',
              opacity: shouldShowExpanded ? 1 : 0,
              visibility: shouldShowExpanded ? 'visible' : 'hidden',
              position: 'relative',
              height: shouldShowExpanded ? 'auto' : 0,
              overflow: 'hidden',
              borderRadius: 2,
              p: shouldShowExpanded ? 5 : 0,
              margin: shouldShowExpanded ? 0 : '0 -20px 0 20px',
              transform: shouldShowExpanded
                ? 'translateX(0)'
                : 'translateX(20px)',
              pointerEvents: shouldShowExpanded ? 'auto' : 'none',
            }}
          >
            {children}
          </Box>
        </Box>

        {isDirty && (
          <Button
            type="submit"
            variant="outlined"
            disabled={isSubmitting}
            title={`Save (${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+Enter)`}
            sx={{
              mt: 2,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '0.875rem',
              px: 3,
              '&:hover': {
                backgroundColor: 'primary.main',
                color: 'common.white',
                '& .shortcut-text': {
                  color: 'inherit',
                  opacity: 0.7,
                },
              },
            }}
          >
            {isSubmitting ? (
              'Saving...'
            ) : (
              <>
                Save
                <Box
                  component="span"
                  className="shortcut-text"
                  sx={{
                    ml: 1,
                    fontSize: '0.75rem',
                    opacity: 0.5,
                    color: 'text.secondary',
                    transition: 'all 0.2s ease',
                  }}
                >
                  {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+↵
                </Box>
              </>
            )}
          </Button>
        )}
      </Box>
    </FormContext.Provider>
  );
};

// Helper hook to use the form context
export const useFormContext = () => {
  const context = React.useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a BaseNoteForm');
  }
  return context;
};

export default BaseNoteForm;
