import { useCallback, useEffect } from 'react';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { usePatchMatterClientMutation } from '../../../services/api/mattersService';
import { MatterClient } from '../../../services/types/matter-client-types';
import { useNotification } from '../../contexts/NotificationContext';

// Common styles for form fields
const inputSx = {
  '&.MuiInput-root': {
    '&:before, &:after': {
      display: 'none !important',
      borderBottom: 'none !important',
    },
    '& input': {
      padding: 0,
      color: 'info.light',
      fontSize: '0.875rem',
      minHeight: '24px',
    },
  },
};

const labelSx = {
  fontSize: '0.875rem',
  fontWeight: 600,
  letterSpacing: '0.08em',
  color: 'text.primary',
};

const MatterClientFormV2 = () => {
  const form = useFormContext();
  const {
    fields: phoneNumberFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'phone_numbers',
  });

  // Add onChange handlers that mark form as dirty
  const handleChange = (name: string, value: string) => {
    form.setValue(name, value, { shouldDirty: true });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Grid container spacing={4}>
        {/* Row 1: Basic Info */}
        <Grid item xs={4}>
          <TextField
            size="small"
            label="NAME"
            name="name"
            value={form.watch('name') || ''}
            onChange={(e) => handleChange('name', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Enter client name"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size="small"
            label="EMAIL"
            name="email"
            type="email"
            value={form.watch('email') || ''}
            onChange={(e) => handleChange('email', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Enter email address"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size="small"
            label="DATE OF BIRTH"
            name="date_of_birth"
            type="date"
            value={form.watch('date_of_birth') || ''}
            onChange={(e) => handleChange('date_of_birth', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Select date of birth"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        {/* Row 2: Address Line 1 */}
        <Grid item xs={4}>
          <TextField
            size="small"
            label="STREET"
            name="address.street"
            value={form.watch('address.street') || ''}
            onChange={(e) => handleChange('address.street', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Enter street address"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size="small"
            label="CITY"
            name="address.city"
            value={form.watch('address.city') || ''}
            onChange={(e) => handleChange('address.city', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Enter city"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            size="small"
            label="STATE"
            name="address.state"
            value={form.watch('address.state') || ''}
            onChange={(e) => handleChange('address.state', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Enter state"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        {/* Row 3: Address Line 2 + First Phone */}
        <Grid item xs={4}>
          <TextField
            size="small"
            label="ZIP CODE"
            name="address.zip_code"
            value={form.watch('address.zip_code') || ''}
            onChange={(e) => handleChange('address.zip_code', e.target.value)}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: labelSx,
            }}
            placeholder="Enter zip code"
            InputProps={{
              sx: inputSx,
            }}
          />
        </Grid>

        {/* Phone Numbers */}
        {phoneNumberFields.map((field, index) => (
          <Grid item xs={4} key={field.id}>
            <TextField
              size="small"
              label={`PHONE ${index + 1}`}
              value={form.watch(`phone_numbers.${index}.phone_number`) || ''}
              onChange={(e) =>
                handleChange(
                  `phone_numbers.${index}.phone_number`,
                  e.target.value,
                )
              }
              variant="standard"
              fullWidth
              placeholder="Enter phone number"
              InputProps={{
                sx: inputSx,
                endAdornment: (
                  <Button
                    size="small"
                    onClick={() => remove(index)}
                    sx={{ color: 'error.main' }}
                  >
                    Remove
                  </Button>
                ),
              }}
              InputLabelProps={{
                shrink: true,
                sx: labelSx,
              }}
            />
          </Grid>
        ))}

        {/* Add Phone Button */}
        <Grid item xs={12}>
          <Button
            startIcon={<AddIcon sx={{ fontSize: '1rem' }} />}
            onClick={() => append({ id: '', phone_number: '' })}
            variant="text"
            size="small"
            sx={{
              color: 'text.secondary',
              fontSize: '0.75rem',
              textTransform: 'none',
              '&:hover': {
                color: 'primary.main',
                backgroundColor: 'transparent',
              },
            }}
          >
            Add Phone Number
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

interface MatterClientNoteSectionProps {
  client: MatterClient;
  matterId: string;
}

const MatterClientNoteSection = ({
  client,
  matterId,
}: MatterClientNoteSectionProps) => {
  const notify = useNotification();
  const [patchMatterClient] = usePatchMatterClientMutation();

  const form = useForm<MatterClient>({
    defaultValues: client,
    mode: 'onChange',
  });

  const handleSubmit = async (data: MatterClient) => {
    try {
      await patchMatterClient({
        matter_id: matterId,
        matter_client_id: client.id,
        patch: data,
      }).unwrap();
      notify('Client updated successfully', 'success', 3000);
      form.reset(data);
    } catch (error) {
      console.error('Failed to update client:', error);
      notify('Failed to update client', 'error', 3000);
    }
  };

  // Add keyboard shortcut handler
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault();
        if (form.formState.isDirty && !form.formState.isSubmitting) {
          form.handleSubmit(handleSubmit)();
        }
      }
    },
    [form, handleSubmit],
  );

  // Add and remove event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <FormProvider {...form}>
      <Box
        sx={{
          mb: 2,
          pb: 3,
          borderBottom: '1px solid',
          borderColor: 'border.main',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h2">Client Information</Typography>
          {form.formState.isDirty && (
            <Button
              variant="outlined"
              onClick={form.handleSubmit(handleSubmit)}
              disabled={form.formState.isSubmitting}
              title={`Save (${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+Enter)`}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '0.875rem',
                px: 3,
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'common.white',
                  '& .shortcut-text': {
                    color: 'inherit',
                    opacity: 0.7,
                  },
                },
              }}
            >
              {form.formState.isSubmitting ? (
                'Saving...'
              ) : (
                <>
                  Save
                  <Box
                    component="span"
                    className="shortcut-text"
                    sx={{
                      ml: 1,
                      fontSize: '0.75rem',
                      opacity: 0.5,
                      color: 'text.secondary',
                      transition: 'all 0.2s ease',
                    }}
                  >
                    {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+↵
                  </Box>
                </>
              )}
            </Button>
          )}
        </Stack>
        <MatterClientFormV2 />
      </Box>
    </FormProvider>
  );
};

export default MatterClientNoteSection;
